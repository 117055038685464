import { useState } from 'react'
import { Input } from 'reactstrap'
import JpLabel from './JpLabel'

const JpTextAreaInput = ({
  name,
  invalid = false,
  optionsInput = {},
  title,
  disabled = false,
  maxStringLength = 200
}) => {
  const [stringLength, setStringLength] = useState(0)
  return (
    <>
      {title && <JpLabel labelOptions={{ for: name }}>{title}</JpLabel>}
      <Input
        type="textarea"
        id={name}
        invalid={invalid}
        disabled={disabled}
        style={{ minHeight: '100px', maxHeight: '110px' }}
        {...optionsInput}
        onChange={v => {
          if (v.target.value) setStringLength(v.target.value.length)
          else setStringLength(0)
          if (optionsInput.onChange) {
            optionsInput.onChange(v)
          }
        }}
      />
      {maxStringLength > 0 && (
        <span
          className={`textarea-counter-value float-end ${stringLength > maxStringLength ? 'bg-warning' : 'bg-info'}`}
        >
          {`${stringLength}/${maxStringLength}`}
        </span>
      )}
    </>
  )
}

export default JpTextAreaInput
