export const PRODUCTUVITY_STATUS_VALUES = {
  ACTIVE: {
    icon: 'fa-solid fa-person-rays',
    color: 'success',
    i18n: 'views.productivity.states.ACTIVE',
    i18nBO: 'views.clients.productivity.states.ACTIVE'
  },
  AFK: {
    icon: 'fa-solid fa-laptop-slash',
    color: 'warning',
    i18n: 'views.productivity.inactives',
    i18nBO: 'views.clients.productivity.states.AFK'
  },
  DISCONNECTED: {
    icon: 'fa-solid fa-user-slash',
    color: 'secondary',
    i18n: 'views.productivity.states.DISCONNECTED',
    i18nBO: 'views.clients.productivity.states.DISCONNECTED'
  },
  ON_CALL: {
    icon: 'fa-solid fa-phone',
    color: 'info',
    i18n: 'views.productivity.states.ON_CALL',
    i18nBO: 'views.clients.productivity.states.ON_CALL'
  }
}
