// ** React Imports
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import ReactPaginate from 'react-paginate'
import DataTable from 'react-data-table-component'
import { Card, CardHeader, CardTitle, Row, Col, Input, InputGroup, InputGroupText, CardFooter } from 'reactstrap'

import JpIcon from '../icons/JpIcon'
// ** Utils imports
import NoData from '../NoData'
import { SpinnerLoader } from '../../Intelli-utilities'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const calculatedRows = Math.abs(Math.floor((window.innerHeight - 62 - 47 - 64 - 126) / 60))
const perPage = calculatedRows > 10 ? calculatedRows : 10

const JpTableV1 = ({
  actions,
  title,
  cols,
  selectable,
  fetcher,
  loading,
  data,
  total,
  queriableData = '',
  options,
  search = true,
  paginationServer = true,
  sortServer = true,
  tableClassName = '',
  paginatorClassName = '',
  footer,
  footerClassname = '',
  defaultSort,
  defaultSortAsc,
  cardHeaderClassName = '',
  scrollTop = false,
  offset,
  searchClassname='',
  rowsSelected,
  selectedChanges=()=>{},
  conditionalRowStyles={conditionalRowStyles},
  selectableRowDisabled
}) => {
  // ** States
  const [currentPage, setCurrentPage] = useState(0)
  const [queries, setQueries] = useState(queriableData)

  const { t } = useTranslation()

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  const handlePagination = ({ selected }) => {
    setCurrentPage(selected)
  }

  const _handleSearch = ({ target: { value } }) => {
    setCurrentPage(0)
    setQueries(value)
  }

  const handleSort = async (column, sortDirection) => {
    refrestData({ sort: `${column.sortable}__${sortDirection}` })
  }

  // ** Custom Pagination
  const CustomPagination = () => (
    <ReactPaginate
      nextLabel=""
      breakLabel="-"
      previousLabel=""
      pageRangeDisplayed={2}
      forcePage={offset === 0 ? 0 : currentPage}
      marginPagesDisplayed={2}
      activeClassName="active"
      pageClassName="page-item"
      breakClassName="page-item"
      nextLinkClassName="page-link"
      pageLinkClassName="page-link"
      breakLinkClassName="page-link"
      previousLinkClassName="page-link"
      nextClassName="page-item next-item"
      previousClassName="page-item prev-item"
      pageCount={Math.ceil(total / perPage) || 1}
      onPageChange={page => handlePagination(page)}
      containerClassName={`pagination react-paginate pagination-sm separated-pagination justify-content-end pe-1 mt-1 ${
        paginatorClassName && paginatorClassName
      }`}
    />
  )

  useEffect(() => {
    const timeout = setTimeout(() => {
      refrestData()
    }, 300)
    return () => {
      clearInterval(timeout)
    }
  }, [queries, currentPage])

  useEffect(() => {
    if (scrollTop) {
      const timeout = setTimeout(() => {
        scrollToTop()
      }, 300)
      return () => {
        clearInterval(timeout)
      }
    }
  }, [currentPage])




  const refrestData = (options = {}) =>
    fetcher({ page: currentPage * perPage, limit: perPage, query: queries, ...options })

  return (
    <Card className={`overflow-hidden ${tableClassName}`}>
      {title && !options?.customTitle ? (
        <CardHeader className={`pb-1 ${cardHeaderClassName ? cardHeaderClassName : ''}`}>
          <CardTitle tag="h4">{title}</CardTitle>
        </CardHeader>
      ) : (
        <></>
      )}
      {options?.customTitle ? <CardHeader className="pb-0">{options.customTitle}</CardHeader> : <></>}
      {(actions || search) && (
        <Row className="justify-content-start mx-0 my-1">
          {search && (
            <Col className={`d-flex align-items-center justify-content-end ${searchClassname}`} md="3" sm="12">
              <InputGroup className="input-group-merge w-100" md="4">
                <InputGroupText>
                  <JpIcon icon="magnifying-glass" />
                </InputGroupText>
                <Input
                  autoComplete="off"
                  type="text"
                  id="search-input"
                  placeholder={t('input.search.placeholder')}
                  onChange={_handleSearch}
                />
              </InputGroup>
            </Col>
          )}
          {actions && <Col className={`${options?.actionsClassName || ''}`}>{actions}</Col>}
        </Row>
      )}
      <div className="react-dataTable">
        <DataTable
          onSort={handleSort}
          sortServer={sortServer}
          noHeader
          pagination={true}
          title={title}
          data={data}
          columns={cols}
          progressPending={loading}
          progressComponent={<SpinnerLoader />}
          persistTableHead
          noDataComponent={<NoData color="light" size="5x" />}
          className="react-dataTable"
          sortIcon={<FontAwesomeIcon icon={`fa-chevron-down`}  style={{height:10}} className={''} />}
          paginationComponent={CustomPagination}
          paginationServer={paginationServer}
          paginationTotalRows={total}
          paginationPerPage={perPage}
          paginationDefaultPage={currentPage + 1}
          defaultSortFieldId={defaultSort}
          defaultSortAsc={defaultSortAsc}
          selectableRows={selectable || false}
          selectableRowDisabled={selectableRowDisabled}
          onSelectedRowsChange={selectedChanges}
          selectableRowSelected={rowsSelected}
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
      {footer && <CardFooter className={footerClassname}>{footer}</CardFooter>}
    </Card>
  )
}

JpTableV1.propTypes = {
  actions: PropTypes.element,
  title: PropTypes.string,
  cols: PropTypes.arrayOf(PropTypes.object).isRequired,
  // statusChange: PropTypes.bool.isRequired,
  selectable: PropTypes.bool,
  fetcher: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.any]),
  queriableData: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      operator: PropTypes.string,
      value: PropTypes.string
    })
  ),
  options: PropTypes.shape({
    perPage: PropTypes.number,
    customTitle: PropTypes.element,
    actionsClassName: PropTypes.string
  })
}

export default JpTableV1
