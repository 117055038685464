import { faCloudArrowUp, faFileZipper, faXmark } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
import { Button, Col, ListGroup, ListGroupItem, Row } from 'reactstrap'
import PropTypes from 'prop-types'
import SweetModal from '../SweetModal'

const defaultProps = {
  maxFileSize: 20971520, // 20MB
  acceptedFormats: ['.pdf'],
  multiple: false,
  maxFiles: 1,
  inputShort: false
}

const mimeTypes = {
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'image/*': ['.jpg', '.jpeg', '.png'],
  'application/pdf': ['.pdf'],
  'application/x-apple-diskimage': ['.dmg'],
  'application/x-msdownload': ['.exe']
}

const fileExtensions = [
  '.doc', // application/msword
  '.docx', // application/vnd.openxmlformats-officedocument.wordprocessingml.document
  '.jpg', // image/jpeg
  '.jpeg', // image/jpeg
  '.png', // image/png
  '.pdf', // application/pdf,
  '.dmg', // application/x-apple-diskimage
  '.exe', // application/x-msdownload
  '.txt'
]

const getMimeTypeFromExtensions = extensions => {
  const mimes = {}

  for (const [key, value] of Object.entries(mimeTypes)) {
    for (const ext of extensions) {
      if (value.includes(ext)) {
        mimes[`${key}`] = value
      } else {
        //console.warn(`getMimeTypeFromExtensions: ${ext} is not a valid file extension for ${key} mime type.`)
      }
    }
  }

  return mimes
}

const JpFileInput = ({
  files,
  setFiles,
  fileInvalid,
  setFileInvalid,
  maxFileSize = defaultProps.maxFileSize,
  acceptedFormats = defaultProps.acceptedFormats,
  multiple = defaultProps.multiple,
  maxFiles = defaultProps.maxFiles,
  inputShort = defaultProps.inputShort
}) => {
  const { t } = useTranslation()

  const formats = getMimeTypeFromExtensions(acceptedFormats)

  const { getRootProps, getInputProps } = useDropzone({
    multiple,
    maxFiles,
    accept: formats,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length) {
        SweetModal(
          'warning',
          t('titles.atention', { ns: 'errors' }),
          t('input.file.maxSize', { ns: 'errors' }),
          t('buttons.ok', { ns: 'errors' })
        )
      } else {
        setFileInvalid(false)
        // setFiles([...files, ...acceptedFiles.map(file => Object.assign(file))])
        setFiles(lastFiles => {
          const newFiles = [...lastFiles, ...acceptedFiles.map(file => Object.assign(file))]
          return newFiles.slice(-5)
        })
      }
    },
    maxSize: maxFileSize
  })

  const handleRemoveFile = file => {
    const uploadedFiles = files
    const filtered = uploadedFiles.filter(i => i.name !== file.name)
    setFiles([...filtered])
  }

  const renderFileSize = size => {
    if (Math.round(size / 100) / 10 > 1000) {
      return `${(Math.round(size / 100) / 10000).toFixed(1)} MB`
    } else {
      return `${(Math.round(size / 100) / 10).toFixed(1)} KB`
    }
  }

  const fileList = files.map((file, index) => (
    <Col key={`${file.name}-${index}`} className={`${multiple ? 'col-3 mt-50' : ''}`}>
      <ListGroupItem  className="d-flex align-items-center justify-content-between">
        <div className="file-details d-flex align-items-center text-truncate">
          <div className="file-preview me-1">
            <FontAwesomeIcon icon={faFileZipper} size="lg" />
          </div>
          <div>
            <p className="file-name mb-0">{file.name}</p>
            {file.size && <p className="file-size mb-0">{renderFileSize(file.size)}</p>}
          </div>
        </div>
        <div>
          <Button
            color="danger"
            outline
            size="sm"
            className="btn-icon ms-75 px-75"
            onClick={() => handleRemoveFile(file)}
          >
            <FontAwesomeIcon icon={faXmark} />
          </Button>
        </div>
      </ListGroupItem>
    </Col>
  ))

  return (
    <>
      {!inputShort && (
        <div
          {...getRootProps({
            className: 'dropzone align-items-center'
          })}
        >
          <input id="template" {...getInputProps()} />
          <div
            className={`d-flex align-items-center justify-content-center flex-column border rounded p-0 py-1 ${
              fileInvalid ? 'is-invalid' : ''
            }`}
          >
            <FontAwesomeIcon icon={faCloudArrowUp} size="4x" />
            <p className="text-center mt-75">{t('input.file.title')}</p>
            <p className="text-secondary">
              {t('input.file.drop')}
              <a href="/" onClick={e => e.preventDefault()}>
                {t('input.file.browse')}
              </a>{' '}
              {t('input.file.machine')}
            </p>
          </div>
        </div>
      )}
      {(inputShort) && (
        <div
          {...getRootProps({
            className: 'dropzone align-items-center'
          })}
        >
          <input id="template" {...getInputProps()} />
          <div
            className={`d-flex align-items-center justify-content-center flex-column border rounded p-0 py-1 ${
              fileInvalid ? 'is-invalid' : ''
            }`}
          >
            <FontAwesomeIcon icon={faCloudArrowUp} size="2xl" />
            <p className="text-center mt-75 mb-0">{t('input.file.title')}</p>
          </div>
        </div>
      )}
      {files.length > 0 && <ListGroup className="mb-1">{<Row>{fileList}</Row>}</ListGroup>}
      {fileInvalid && <small className="text-danger">{t('input.file.required', { ns: 'errors' })}</small>}
    </>
  )
}

JpFileInput.propTypes = {
  files: PropTypes.array,
  setFiles: PropTypes.func,
  fileInvalid: PropTypes.bool,
  setFileInvalid: PropTypes.func,
  maxFileSize: PropTypes.number,
  multiple: PropTypes.bool,
  acceptedFormats: PropTypes.arrayOf(PropTypes.oneOf(fileExtensions)),
  inputShort: PropTypes.bool
}

export default JpFileInput
