function intersection(a, b) {
  return a.filter(Set.prototype.has, new Set(b)).length > 0
}

/**
 * modules = LOS MODULOS A LOS CUALES EL USUARIO TIENE PERMISO
 * arrayModules = SON LOS EL MODULO CON LA PROFUNDIDAD A LA CUAL SE QUIERE VERIFICAR, EL ULTIMO ELEMENTO DEBE TENER moduleOperation
 *  ["PAYROLL","PAROLL-RECORDS"]
 * arrayPermission = SON LAS OPERACIONES QUE SE ESTAN COMPROBANDO QUE POSEE, 
 *   1 / READ
 *   2 / READ-WRITE
 *   3 / SENSITIVE-READ
 *   4 / SENTIVE-READ-WRITE
 */

const hasPermissionModules = (modules, arrayModules, arrayPermission = [1, 2, 3, 4]) => {
  const name = arrayModules[0]
  const mo = modules.find(m => m.name === name)
  if (mo) {
    if (arrayModules.length > 1) {
      if (mo && mo.children) {
        arrayModules.shift()
        return hasPermissionModules(mo.children, arrayModules, arrayPermission)
      }
    } else {
      if (mo.moduleOperation)
        return intersection(
          mo.moduleOperation.map(i => i.operation_id),
          arrayPermission
        )
    }
  }
  return false
}

export
default {
  Read: (modules, arrayModules) =>
    hasPermissionModules(modules, arrayModules),
  WriteUpdate: (modules, arrayModules) =>
    hasPermissionModules(modules, arrayModules, [2, 4])
}