import React from 'react'
import JpAvatar from './JpAvatar'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import JpIcon from '../icons/JpIcon'
import parseName from '../../functions/parseName'
import { useTranslation } from 'react-i18next'
import { faEdit } from '@fortawesome/pro-solid-svg-icons'

const ContractorProfilePhoto = ({ photo, getInputProps, getRootProps, first_name, last_name }) => {
  const { t } = useTranslation()
  return (
    <>
      <div className="d-flex align-items-center justify-content-center hover-div-button mb-1 mb-lg-0">
        <input {...getInputProps()} />
        <div
          {...getRootProps({
            className: 'dropzone avatar mr-1 '
          })}
        >
          <Button
            id={`edit-profile`}
            color="link"
            size="md"
            className="m-0 p-0 bg-info avatar-status-xl text-white"
            style={{ height: '33px', width: '33px', zIndex: '99' }}
          >
            <FontAwesomeIcon icon={faEdit} size={'lg'} />
          </Button>

          <UncontrolledTooltip placement="top" target={`edit-profile`}>
            {t('button.edit')}
          </UncontrolledTooltip>
          {photo ? (
            <img src={photo} width="125" height="125" className="bg-white border avatar-cover" />
          ) : (
            <JpAvatar
              content={parseName(first_name || last_name)}
              initials
              color="primary"
              contentStyles={{
                height: 100,
                width: 100,
                fontWeight: 'bold',
                fontSize: 30,
                cursor: 'pointer'
              }}
              size="xxxl"
            />
          )}
        </div>
      </div>
    </>
  )
}

export default ContractorProfilePhoto
