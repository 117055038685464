export default function JpBombLight({ id = '', color = '#82868b', state = {}, size='' }) {
    const colors = {
      ON_CALL: {
        color: '#28c76f'
      },
      AFK: {
        color: '#ff9f43'
      },
      ACTIVE: {
        color: '#28c76f'
      },
      DISCONNECTED: {
        color: '#82868b'
      },
      CLOCK_OUT: {
        color: '#82868b'
      }
    }
    const sizeBomb = size === 'lg' ?  '30px' : '14px'
    return (
      <div
        id={`bombLight${id}`}
        style={{
          borderRadius: '100px 100px 100px 100px',
          width: sizeBomb,
          height: sizeBomb,
          marginLeft: size === 'lg' ? -25 : -10,
          border: size === 'lg' ? 'solid white 3px' : 'solid white 0.5px',
          backgroundColor: colors[state]?.color,
          zIndex: 50
        }}
      ></div>
    )
  }
  
