import { JpAvatar } from '@Intelli/utilities'

import { components } from 'react-select'

const OptionComponentSelectAvatar = ({ data, ...props }) => {
    if (data.value === 'ALL') return <components.Option {...props} />

    return (
        <components.Option {...props}>
            <JpAvatar
                className="me-1"
                color={`light-info`}
                img={data?.photo}
                imgClassName="avatar-cover cursor-default"
                {...(!!!data?.photo
                    ? { content: data.label, initials: true }
                    : {})}
            />
            {data.label}
        </components.Option>
    )
}

export default OptionComponentSelectAvatar