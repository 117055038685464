import axios from 'axios'
import { API_URL } from '../configs/environment'
import { globalHandler } from '@Intelli/utilities'

export default class UtilitiesAPI {
  constructor(baseURL = API_URL) {
    this.API = axios.create({
      baseURL,
      withCredentials: true,
      headers: {
        lng: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en'
      }
    })
    this.API.interceptors.response.use(
      request => {
        return request
      },
      error => {
        console.log(error)
        globalHandler(error.response.data)
        return Promise.reject(error)
      }
    )
  }

  async userAccess() {
    try {
      const response = await this.API.get('v1/auth/session/lobby')
      return response.data
    } catch (e) {
      throw e.response.data
    }
  }

  async send2faCode(data) {
    try {
      const response = await this.API.post('v1/verification/twoFactor/send', data)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  async validate2faCode(data) {
    try {
      const response = await this.API.post('v1/verification/twoFactor/validate', data)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  async logout() {
    try {
      const response = await this.API.post('v1/auth/logout')
      localStorage.clear()
      return response.data
    } catch (e) {
      throw e.response
    }
  }

  async set2fa(data) {
    try {
      const response = await this.API.post('v1/auth/session/twoFactor', data)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  async reportRenderError(data) {
    try {
      const response = await this.API.post('v1/auth/errors/front', data)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
}
