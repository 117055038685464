import PropTypes from "prop-types"
import { JpButton } from "../../Intelli-utilities"

const defaultProps = {
    options: {
        backRenderCondition: true,
        nextRenderCondition: true,
        doneRenderCondition: true,
        buttonsColor: "info"
    }
}

const JpStepperButtons = ({ onBack, onNext, onDone, isLastStep, isFirstStep, options = defaultProps.options }) => {
    return (<div
        className={
          currentIndex !== 0 ? 'd-flex justify-content-between' : 'text-end'
        }
      >
        {(!isFirstStep || options.backRenderCondition) && (
          <JpButton
            color={options.buttonsColor}
            className="me-75"
            type="back"
            {...(options.backText ? {text: options.backText} : {})}
            options= { {onClick: () => onBack(), iconPosition: 'right', ...(options.backIcon ? {customIcon: options.backIcon} : {})} }
          />
        )}
        {(!isLastStep || options.nextRenderCondition) && <JpButton
            color={options.buttonsColor}
            type="next"
            {...(options.nextText ? {text: options.nextText} : {})}
            options= { {onClick: () => onNext(), iconPosition: 'left', ...(options.nextIcon ? {customIcon: options.nextIcon} : {})} }
            />}
        {(isLastStep || options.doneRenderCondition) && <JpButton
            color={options.buttonsColor}
            type="save&send"
            {...(options.doneText ? {text: options.doneText} : {})}
            options= { {onClick: () => onDone(), iconPosition: 'left', ...(options.doneIcon ? {customIcon: options.doneIcon} : {})} }
            />}
      </div>)
}

JpStepperButtons.propTypes = {
    onBack: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    isLastStep: PropTypes.bool.isRequired,
    isFirstStep: PropTypes.bool.isRequired,
    options: PropTypes.exact({
        backRenderCondition: PropTypes.bool,
        nextRenderCondition: PropTypes.bool,
        doneRenderCondition: PropTypes.bool,
        backText: PropTypes.string,
        nextText: PropTypes.string,
        doneText: PropTypes.string,
        backIcon: PropTypes.element,
        nextIcon: PropTypes.element,
        doneIcon: PropTypes.element,
        buttonsColor: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark', 'link'])
    })
}

export default JpStepperButtons