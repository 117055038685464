import { useTranslation } from 'react-i18next'
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Progress, UncontrolledTooltip } from 'reactstrap'
import JpCardSkeleton from '../skeletons/JpCardSkeleton'

const JpProgressBar = ({
  title = '',
  styles = {},
  footer = {},
  value = 0,
  max = 100,
  minutes = 0,
  loading = false
}) => {
  if (loading) return <JpCardSkeleton className="h-100" loading={loading} graph />
  return (
    <Card style={styles}>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      <CardBody className="pt-1">
        <Progress
          className="mt-2"
          color="warning"
          /* barClassName="ms-5 rounded-pill" */
          value={value}
          max={max}
          id="progress"
        />
        <UncontrolledTooltip target="progress">{minutes && minutes}</UncontrolledTooltip>
      </CardBody>
      <CardFooter className="border-0 mt-0 pt-0 pb-75">{footer}</CardFooter>
    </Card>
  )
}

export default JpProgressBar
