export default function hideEmail(email) {
  const chars = 0
  if (email) {
    return email.replace(
      /[a-z0-9\-_.]+@/gi,
      c =>
        `${
          c.substr(0, chars) +
          c
            .split('')
            .slice(chars, -1)
            .map(() => '*')
            .join('')
        }@`
    )
  }
}
