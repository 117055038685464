import { Button } from "reactstrap"
import CountDownTimer from "./CountDownTimer"

const TimerButton = ({type = "button", isDisabled, onClick, expires, buttonText = '', disabledText, color, className, options}) => {
    return (
        <div>  
            <Button
                type={type}
                color={color || "link"}
                className={`${color !== 'link' ? className : 'button-send-verification'}`}
                disabled={isDisabled}
                onClick={onClick}
                {...options}
              >
                {isDisabled ? (
                  <>{disabledText} <CountDownTimer hoursMinSecs={expires} /></>
                ) : (
                    buttonText
                )}
              </Button>
          </div>
    )
}

export default TimerButton