import Chart from 'react-apexcharts'
import JpCardSkeleton from '../skeletons/JpCardSkeleton'
import { Card, CardTitle, CardBody, Row, Col, CardHeader } from 'reactstrap'
import { getColorHex } from '../../functions/getColorHex'

const JpDonutWithTextGraph = ({
  isLoading,
  title,
  series = [],
  labels = [],
  defaultLabel,
  defaults,
  colors,
  customSize,
  IntLabelFormat,
  customBody,
  classNameCard = '',
  bodyClassName = '',
  headerClassName = '',
  customFormatterTotal,
  customBodyColClassName,
  height = 130
}) => {
  const options = {
    chart: {
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: { show: false },
    tooltip: {
      enabled: false
    },
    comparedResult: [2, -3],
    labels,
    stroke: { width: 0 },
    // colors: ['#28C76F', '#28c76f66'],
    colors: colors
      ? colors?.map((color, indx) => getColorHex(color, false, 1 - 0.05 * indx))
      : ['#28C76F', '#28c76f66'],
    /*  grid: {
      padding: {
        right: -20,
        bottom: -8,
        left: -20
      }
    }, */
    plotOptions: {
      pie: {
        customScale: 1,
        donut: {
          size: customSize ? customSize : '75%',
          labels: {
            show: true,
            name: {
              offsetY: 15
            },
            value: {
              offsetY: -15,
              formatter(val) {
                return IntLabelFormat ? `${val}` : `${parseFloat(val).toFixed(2)}%`
              }
            },
            total: {
              show: true,
              offsetY: 15,
              label: defaults ? defaults?.label : defaultLabel ? defaultLabel : labels[0],
              formatter(val) {
                if(customFormatterTotal){
                  return customFormatterTotal(val)
                }
                if (IntLabelFormat) return defaults?.value
                if (!!defaults) return `${parseFloat(defaults?.value).toFixed(2)}%`
                return `${parseFloat(val.config.series[0]).toFixed(2)}%`
              }
            }
          }
        }
      }
    }
    /* responsive: [
      {
        breakpoint: 1325,
        options: {
          chart: {
            height: 100
          }
        }
      },
      {
        breakpoint: 1200,
        options: {
          chart: {
            height: 95
          }
        }
      },
      {
        breakpoint: 1065,
        options: {
          chart: {
            height: 100
          }
        }
      },
      {
        breakpoint: 992,
        options: {
          chart: {
            height: 120
          }
        }
      }
    ] */
  }

  if (isLoading) return <JpCardSkeleton className="h-100" loading={isLoading} graph />

  //if series is empty or all values are 0, replace the series with a default value [100]
  if (series.length === 0 || series.every(value => value === 0)) {
    options.colors = [getColorHex('secondary', false, 0.1)]
    series = [100]
    options.tooltip = {
      enabled: false
    }
  }

  return (
    <Card className={`earnings-card h-100 match-height ${classNameCard} mb-0`}>
      {title && (
        <CardHeader className={`${headerClassName}`}>
          <CardTitle>{title}</CardTitle>
        </CardHeader>
      )}
      <CardBody className={`pb-0 ${bodyClassName}`}>
        <Row className={`justify-content-between ${customBody ? '' : 'align-items-center'}`}>
          {customBody && <Col xs="6" className={`${customBodyColClassName}`}>{customBody}</Col>}
          <Col xs={customBody ? '6' : '12'} className="px-0" >
            <Chart options={options} series={series} type="donut" height={height} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default JpDonutWithTextGraph
