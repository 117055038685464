import { colors$ } from '../Intelli-utilities'

const getRgba = (hex, alpha) => {
  const rgb = hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1)
    .match(/.{2}/g)
    .map(x => parseInt(x, 16))
  return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${alpha})`
}

export const getColorHex = (color, light = false, alpha) => {
  if (color && color.includes('#')) {
    return color
  }
  if (light) {
    return colors$.value[color] ? colors$.value[color]?.light : null
  }

  return colors$.value[color] ? getRgba(colors$.value[color]?.main, alpha) : null
}
