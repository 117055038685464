const iconStarFn = rating => {

    const ratingNumber = parseFloat(rating)

    const classNameComun = `text-warning fs-5`

    if (ratingNumber >= 0.5 && ratingNumber <= 2)
        return <i className={`fa-kit fa-estrella-1 ${classNameComun}`}></i>

    if (ratingNumber >= 2 && ratingNumber <= 3)
        return <i className={`fa-kit fa-estrella-2 ${classNameComun}`}></i>

    if (ratingNumber >= 3 && ratingNumber <= 4.5)
        return <i className={`fa-kit fa-estrella-3 ${classNameComun}`}></i>

    if (ratingNumber === 5)
        return <i className={`fa-kit fa-estrella-4 ${classNameComun}`}></i>

    return ''
}

export default iconStarFn