import { Card, CardBody, CardFooter, CardHeader, CardSubtitle, CardTitle, Col } from 'reactstrap'
import JpBars from '../graphs/JpBars'
import JpPieGraph from '../../components/general/JpPieGraph'
import { parseMinutesToHours } from '../../Intelli-utilities'
import percentage from '../../functions/percentage'
import JpCardSkeleton from '../skeletons/JpCardSkeleton'

const JpPieCardTime = ({
  title,
  subtitle,
  footer,
  cardClassName,
  headerClassName,
  footerClassName,
  cardBodyHeight = {},
  leftInfo = <></>,
  bodyClassName = '',
  data = {},
  style,
  loading = false
}) => {
  const colors = {
    total: 'success',
    extra: 'info'
  }


  const donutData = {...data}
  delete donutData.percent

  if(loading) return <JpCardSkeleton bodyText={false} className='h-100 p-0 m-0' loading={loading} graph />

  return (
    <Card className={cardClassName}>
      {title && (
        <CardHeader className={`pb-0 ${headerClassName}`}>
          <CardTitle>{title}</CardTitle>
          <CardSubtitle>{subtitle}</CardSubtitle>
        </CardHeader>
      )}
      <CardBody
        className={`d-flex justify-content-between align-items-center pb-0 ${bodyClassName}`}
        style={cardBodyHeight}
      >
        {leftInfo && leftInfo}
        <Col xs="6">
          <JpPieGraph
            legendShow={false}
            graphData={Object.values(donutData)?.map(i => Number(i))}
            graphLabels={Object.keys(donutData)?.map(i => i)}
            type="donut"
            tooltipFormatter={value => `${parseMinutesToHours(value)}`}
            graphColors={Object.keys(donutData)?.map(type => colors[type])}
            legendPosition="bottom"
            cropLongLabels={false}
          />
        </Col>
      </CardBody>
      {footer && <CardFooter className={footerClassName}>{footer}</CardFooter>}
    </Card>
  )
}

export default JpPieCardTime
