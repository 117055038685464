import { useEffect, useState } from 'react'
import { UncontrolledTooltip } from 'reactstrap'

/* 
- This component pretends to 
search in the dom for the id that is passed to see if it matches the child, 
if so show the tooltip and avoid the throw new Error of reactstrap.
it uses a useEffect to make the verification later because in the first render it does not detect in the dom the children.

Example:
    <JpCustomTooltip
        id={`id-${row.contractor_id}`}
        tooltipText={t(`views.productivity.states.${row?.state}`)}       
    >
        <JpAvatar
            id={`id-${row.contractor_id}`}
            key={`id${row.contractor_id}`}
            icon={<i className={usersStates[row.state]?.iconAvatar}></i>}
            color={usersStates[row.state]?.color}
            className={`table-filter__btn me-lg-0 me-1`}
            imgClassName="cursor-default"
        />
    </JpCustomTooltip>
*/

const JpCustomTooltip = ({ id, children, tooltipText }) => {
  const [isTargetInDOM, setIsTargetInDOM] = useState([])

  useEffect(() => {
    setIsTargetInDOM(document.querySelectorAll(`#${id}`))
  }, [])

  return (
    <div>
      {children}
      {isTargetInDOM.length !== 0 && (
        <UncontrolledTooltip placement="top" target={id}>
          {tooltipText}
        </UncontrolledTooltip>
      )}
    </div>
  )
}

export default JpCustomTooltip
