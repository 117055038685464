import { useMemo } from 'react'
import { UncontrolledTooltip } from 'reactstrap'

export default function JpTableTitleName({ text, tooltipText, customClass = '' }) {
  const tooltipId = useMemo(() => `tooltip${crypto.randomUUID()?.replace(/-/g, '')}`, [])
  return (
    <>
      <span
        id={tooltipId}
        style={{ color: '#322B2B' }}
        className={`ms-0 d-block text-truncate text-capitalize fw-normal ${customClass}`}
      >
        {text}
      </span>
      {tooltipText && <UncontrolledTooltip target={tooltipId}>{tooltipText}</UncontrolledTooltip>}
    </>
  )
}
