import { Chart, ArcElement, Legend, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

Chart.register(ArcElement, Tooltip, Legend)

const JpGauge = ({ value = 60, max = 100 }) => {
  const { t } = useTranslation()
  const labels = [
    `${t('gauge.low')}`,
    `${t('gauge.medium')}`,
    `${t('gauge.medium')}`,
    `${t('gauge.high')}`,
    `${t('gauge.high')}`
  ]

  const data = {
    datasets: [
      {
        data: [0.2, 0.2, 0.2, 0.2, 0.2],
        backgroundColor: ['#EA5455', '#FF9F43', '#ffc107', '#81C995', '#28c76f'],
        circumference: 180,
        rotation: 270,
        cutout: '80%',
        needleValue: value
      }
    ]
  }

  const gaugeNeedle = {
    id: 'gaugeNeedle',
    afterDatasetDraw(chart) {
      const {
        ctx,
        data,
        chartArea: { width, height }
      } = chart
      ctx.save()

      const needleValue = data.datasets[0].needleValue
      const angle = Math.PI + (1 / max) * needleValue * Math.PI
      const centerX = width / 2
      const yPos = chart._metasets[0].data[0].y - 10 // could be replaced by bottom - 10

      //Draw the needle
      ctx.translate(centerX, yPos)
      ctx.rotate(angle)
      ctx.beginPath()
      ctx.moveTo(0, -2)
      ctx.lineTo(height - ctx.canvas.offsetTop - 40, 0)
      ctx.lineTo(0, 2)
      ctx.fillStyle = '#000000'
      ctx.fill()

      //Draw the needle center circle
      ctx.translate(-centerX, -yPos)
      ctx.beginPath()
      ctx.arc(centerX, yPos, 10, 0, 10)
      ctx.fill()
      ctx.restore()
    }
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        // enabled: false
        displayColors: false,
        callbacks: {
          label(context) {
            return labels[context.dataIndex]
          }
        }
      }
    },
    animation: {
      duration: 0
    }
  }

  return (
    <div
      className="gauge px-3"
      style={{ position: 'relative', height: '75px', maxHeight: '100%', maxWidth: '100%', width: '250px' }}
    >
      <Doughnut data={data} options={options} plugins={[gaugeNeedle]} />
    </div>
  )
}

export default JpGauge
