import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const CONTRACT_TYPE_VARIABLES = {
  CLASSIC: {
    icon: 'fa fa-chart-simple',
    color: 'primary'
  },
  TIME: {
    icon: 'fa fa-alarm-clock',
    color: 'success'
  },
  TASKS: {
    icon: 'fa fa-list-check',
    color: 'warning'
  }
}

export const CONTRACT_MODALITY_VARIABLES = {
  YOU_HIRE: {
    icon: <FontAwesomeIcon icon={'fa fa-child-reaching'} />,
    color: 'purple',
    name: 'YOU_HIRE'
  },
  WE_HIRE_FOR_YOU: {
    icon: <FontAwesomeIcon icon={'fak fa-joobpay'} />,
    color: 'primary',
    name: 'WE_HIRE_FOR_YOU'
  },
  WE_EMPLOY_FOR_YOU: {
    icon: <FontAwesomeIcon icon={'fak fa-joobpay'} />,
    color: 'success',
    name: 'WE_EMPLOY_FOR_YOU'
  },
  MANAGE: {
    icon: <FontAwesomeIcon icon={'fa-solid fa-building'} />,
    color: 'info',
    name: 'MANAGE'
  },
  PAYROLL: {
    icon: <i class="fa-kit fa-icon-joob-pay-solid"></i>,
    color: 'green',
    name: 'JoobPay'
  },
  TIME: {
    icon: <i class="fa-kit fa-clock-productivity-solid--1-"></i>,
    color: 'info',
    name: 'JoobTime'
  },
  TIME_AND_PRODUCTIVITY: {
    icon: <FontAwesomeIcon icon={'fa-solid fa-arrow-trend-up'} />,
    color: 'cyan'
  }
}
