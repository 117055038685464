import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const TIME_PERMISSIONS_STATUS = {
  PENDING: {
    color: 'warning',
    i18n: 'PENDING',
    icon: <FontAwesomeIcon icon={'fa fa-clipboard-user'} size="lg" />,
    icon_text: 'fa fa-clipboard-user',
    value: 'PENDING'
  },
  REJECTED: {
    color: 'danger',
    i18n: 'REJECTED',
    icon: <FontAwesomeIcon icon={'fa fa-clipboard-list'} size="lg" />,
    icon_text: 'fa fa-clipboard-list',
    value: 'REJECTED'
  },
  APPROVED: {
    color: 'info',
    i18n: 'APPROVED',
    icon: <FontAwesomeIcon icon={'fa fa-clipboard-list-check'} size="lg" />,
    icon_text: 'fa fa-clipboard-list-check',
    value: 'APPROVED'
  },
  PROCESSED: {
    color: 'primary',
    i18n: 'PROCESSED',
    icon: <FontAwesomeIcon icon={'fa fa-clipboard-list'} size="lg" />,
    icon_text: 'fa fa-clipboard-list',
    value: 'PROCESSED'
  },
  EJECUTED: {
    color: 'success',
    i18n: 'EJECUTED',
    icon: <FontAwesomeIcon icon={'fa fa-clipboard-check'} size="lg" />,
    icon_text: 'fa fa-clipboard-check',
    value: 'EJECUTED'
  }
}

export const TIME_PERMISSIONS_STATUS_ARRAY = Object.keys(TIME_PERMISSIONS_STATUS)
