import parseCurrency from "../../functions/parseCurrency"

const JpCurrencyNumber = ({value=0, className='', Tag='span', time=false, currency='US$', id=''}) => {

  const colorText = value =>{
    return Number(value) >= 0 ? 'text-success' : 'text-danger' 
  }

  return (
    <>
      <Tag id={id} className={`${colorText(value)} ${className}`}>{`${parseCurrency(value, currency)} ${time ? '/hrs' : ''}`}</Tag>
      
    </>
  )
}

export default JpCurrencyNumber