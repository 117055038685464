const getDatetoUTC = date => {
  if (date.length == 10) return new Date(`${date} 00:00:00`)
  return new Date(date)
}

const getDateStringtoUTC = date => {
  return getDatetoUTC(date).toISOString()
}

const getDateStringBetweentoUTC = date => {
  const [start, end] = getDateBetweentoUTC(date)
  return [start.toISOString(), end.toISOString()]
}

const getDateBetweentoUTC = date => {
  const startDate = getDatetoUTC(date)
  const endDate = new Date(startDate)
  endDate.setDate(endDate.getDate() + 1)
  return [startDate, endDate]
}

export { getDateStringBetweentoUTC, getDatetoUTC, getDateBetweentoUTC, getDateStringtoUTC }
