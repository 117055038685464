import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap'
import JpAvatar from '../general/JpAvatar'
import JpCardSkeleton from '../skeletons/JpCardSkeleton'

const JpVerticalAvatarCard = ({ title, subtitle, data, loading = false, className='' }) => {
  if (loading) return <JpCardSkeleton loading={loading} graph />
  return (
    <Card className={className}>
      <CardHeader className="pb-0">
        <div className="d-flex justify-content-between align-items-center w-100">
          <CardTitle>{title}</CardTitle>
        </div>
        {!!subtitle && <span className="me-75 mb-1">{subtitle}</span>}
      </CardHeader>
      <CardBody className="d-flex flex-column justify-content-between justify-content-md-center justify-content-xl-beetween">
        <Row>
          {data?.map((item, index) => (
            <Col
              xl="12"
              xs="6"
              className="d-flex justify-content-start justify-content-md-center justify-content-xl-start align-items-center my-50"
              key={index}
            >
              <JpAvatar color={item?.color} icon={<i className={item?.icon} />} />
              <span className="ms-50 d-block d-lg-none d-xl-block">{item?.content}</span>
            </Col>
          ))}
        </Row>
      </CardBody>
    </Card>
  )
}

export default JpVerticalAvatarCard
