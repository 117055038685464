import { useEffect, useState } from 'react'

const getUserOperatingSystem = () => {
    const [os, setOs] = useState('')
    useEffect(() => {
      const userAgent = window.navigator.userAgent;
      if (userAgent.indexOf("Win") !== -1) {
        setOs("Windows")
      } else if (userAgent.indexOf("Mac") !== -1) {
        setOs("MacOS")
      } else if (userAgent.indexOf("Linux") !== -1) {
        setOs("Linux")
      } else if (userAgent.indexOf("Android") !== -1) {
        setOs("Android")
      } else if (userAgent.indexOf("iOS") !== -1) {
        setOs("iOS")
      } else {
        setOs("Unknown")
      }
    }, [])

  return (os)
}

export default getUserOperatingSystem