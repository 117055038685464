import { Card, CardBody, CardFooter, CardHeader, CardSubtitle, CardTitle } from 'reactstrap'
import JpBars from '../graphs/JpBars'
import JpCardSkeleton from '../skeletons/JpCardSkeleton'

const JpVerticalBarsCard = ({
  data,
  labels,
  title,
  subtitle,
  footer,
  color,
  height,
  cardClassName,
  seriesLabel,
  headerClassName,
  footerClassName,
  tooltipFormatter,
  noData,
  cardBodyHeight = {},
  additionalInfo = <></>,
  bodyClassName = '',
  loading = false,
  isToday=true,
  bodyText=false,
  styles = {},
}) => {
  if (loading) return <JpCardSkeleton bodyText={bodyText} className='h-100 p-0 m-0' loading={loading} graph />

  return (
    <Card className={cardClassName} style={styles}>
      {title && (
        <CardHeader className={`pb-0 ${headerClassName}`}>
          <CardTitle>{title}</CardTitle>
          <CardSubtitle>{subtitle}</CardSubtitle>
        </CardHeader>
      )}
      <CardBody
        className={`d-flex justify-content-center align-items-center pb-0 ${bodyClassName}`}
        style={cardBodyHeight}
      >
        {additionalInfo && additionalInfo}
        <JpBars
          isToday={isToday}
          data={data}
          labels={labels}
          color={color}
          height={height}
          seriesLabel={seriesLabel}
          tooltipFormatter={tooltipFormatter}
          noData={noData}
        />
      </CardBody>
      {footer && <CardFooter className={footerClassName}>{footer}</CardFooter>}
    </Card>
  )
}

export default JpVerticalBarsCard
