import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap'
import JpPercentageText from '../general/JpPercentageText'
import JpBarsV1 from '../graphs/JpBarsV1'
import JpGraphSkeleton from '../skeletons/JpGraphSkeleton'
import JpCardSkeleton from '../skeletons/JpCardSkeleton'

const JpBarsCardV1 = ({
  title,
  percentage=0,
  stat,
  lastPeriodText,
  barsData=[],
  xTooltipFormatter,
  yTooltipFormatter,
  seriesLabels,
  loading = false,
  stacked = false,
  colors,
  statClassName,
  cardClassName,
  chartHeight,
  showXToolTip=true,
  cardBodyClassName='',
  statPosition=null
}) => {
  if (loading) return <JpCardSkeleton loading={loading} graph />

  return (
    <Card className={`${cardClassName}`}>
      <CardHeader className={`${!!!loading ? 'pb-0 mb-n75' : ''} flex-column align-items-start`}>
        <div
          className={
            'd-flex flex-row flex-lg-column flex-xxl-row justify-content-between align-items-lg-start align-items-xxl-center align-items-center w-100 placeholder-glow'
          }
        >
          {loading ? (
            <>
              <CardTitle tag={Col} lg="6" className="placeholder rounded">
                ...
              </CardTitle>
              <span className="placeholder rounded col-4">...</span>
            </>
          ) : (
            <>
            <div className='d-flex align-items-center'>

              <CardTitle>{title}</CardTitle>
              {statPosition === 'right' && <span className={` ${statClassName ? statClassName : 'me-75 mb-1'}`}>{stat}</span>}
            </div>
              {percentage !== NaN && <div className="d-flex d-lg-none d-xl-flex">
                <JpPercentageText percentage={parseFloat(percentage)} periodText={lastPeriodText} />
              </div>}
            </>
          )}
        </div>
        {loading ? (
          <Row className="placeholder-glow mt-75 ps-50 w-100">
            <Col className="placeholder rounded">...</Col>
          </Row>
        ) : (
          <>
            {statPosition === null && <span className={` ${statClassName ? statClassName : 'me-75 mb-1'}`}>{stat}</span>}
            <div className="d-none d-lg-flex d-xl-none">
              <JpPercentageText percentage={parseFloat(percentage)} periodText={lastPeriodText} />
            </div>
          </>
        )}
      </CardHeader>
      <CardBody className={cardBodyClassName}>
        <Row className="align-items-center justify-content-center">
          {loading ? (
            <JpGraphSkeleton />
          ) : (
            <Col>
              <JpBarsV1
                chartHeight={chartHeight}
                data={barsData}
                xTooltipFormatter={xTooltipFormatter}
                yTooltipFormatter={yTooltipFormatter}
                seriesLabels={seriesLabels}
                stacked={stacked}
                colors={colors}
                showXToolTip={showXToolTip}
              />
            </Col>
          )}
        </Row>
      </CardBody>
    </Card>
  )
}

export default JpBarsCardV1
