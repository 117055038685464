import {
  faAngleLeft,
  faAngleRight,
  faEllipsis,
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
  faExpand,
  faRotateRight,
  faFileArrowDown,
  faForwardFast,
  faBackwardFast,
  faExpandArrows,
  faCompressArrows
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5'
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  Row,
  UncontrolledButtonDropdown
} from 'reactstrap'
import { JpButton, SpinnerLoader } from '../../Intelli-utilities'
import JpButtonGroup from '../buttons/JpButtonGroup'
import JpPDFAction from './JpPDFAction'
import PropTypes from 'prop-types'
import { downloadFile } from './Files'
import { useTranslation } from 'react-i18next'

const headerColors = {
  default: 'bg-white',
  primary: 'bg-primary',
  success: 'bg-success',
  danger: 'bg-danger',
  warning: 'bg-warning',
  info: 'bg-info',
  dark: 'bg-dark'
}

const textColor = {
  default: 'text-dark',
  primary: 'text-white',
  success: 'text-white',
  danger: 'text-white',
  warning: 'text-white',
  info: 'text-white',
  dark: 'text-white'
}

const isImg = ['jpg', 'jpeg', 'png']

const JpPreviewFiles = ({
  pdf,
  pdfName,
  title = 'PDF',
  actions,
  headerColor = 'default',
  defaultScale,
  menuHoverNone = false
}) => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [theta, setTheta] = useState(0)
  const [zoom, setZoom] = useState(defaultScale || 2)
  const [isFullScreen, setIsFullScreen] = useState(false)

  const { t } = useTranslation()

  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      page: 1
    }
  })

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  const goToPrevPage = () => {
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1)
    setValue('page', pageNumber - 1 <= 1 ? 1 : pageNumber - 1)
  }

  const goToNextPage = () => {
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1)
    setValue('page', pageNumber + 1 >= numPages ? numPages : pageNumber + 1)
  }

  const goFullScreen = () => {
    const canvas = document.getElementById('pdfViewer')
    canvas.setAttribute('style', 'overflow: auto !important')
    if (canvas?.requestFullScreen) canvas.requestFullScreen()
    else if (canvas.webkitRequestFullScreen) canvas.webkitRequestFullScreen()
    else if (canvas.mozRequestFullScreen) canvas.mozRequestFullScreen()
    else if (canvas.msRequestFullscreen) canvas.msRequestFullscreen()
    setIsFullScreen(true)
  }

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    }
    setIsFullScreen(false)
  }

  const rotate = () => setTheta(theta === 270 ? 0 : theta + 90)

  const zoomIn = () => setZoom(zoom + 0.5)

  const zoomOut = () => setZoom(zoom - 0.5)

  const changePageNumber = number => {
    setPageNumber(number)
    setValue('page', number)
  }

  const onItemClick = ({ pageNumber: itemPageNumber }) => {
    setPageNumber(itemPageNumber)
  }

  const onSubmit = data => {
    if (data.page > numPages) {
      setValue('page', numPages)
    } else if (data.page < 1) {
      setValue('page', 1)
    } else if (data.page === '') {
      reset()
    } else {
      setPageNumber(parseInt(data.page))
    }
  }

  const onFullScreenChange = () => {
    const fullscreenElement =
      document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement

    if (!fullscreenElement) {
      setIsFullScreen(false)
    }
  }

  document.addEventListener('fullscreenchange', onFullScreenChange, false)
  document.addEventListener('webkitfullscreenchange', onFullScreenChange, false)
  document.addEventListener('mozfullscreenchange', onFullScreenChange, false)

  useEffect(() => {
    if (pdf) {
      setNumPages(null)
      setPageNumber(1)
      reset()
    }
  }, [pdf])


  const fileExtension = pdf.split('.').pop()

  if (isImg.includes(fileExtension)) {
    return (
      <>
        <Card>
          <CardHeader>
            <CardText className="ms-75">{title}</CardText>
          </CardHeader>
          <CardBody className="mx-auto w-75">
            <img src={`${pdf}`} alt={`img-${pdf}`} className="img-fluid" />
          </CardBody>
        </Card>
      </>
    )
  } else if (fileExtension === 'pdf') {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center" id="pdfViewer">
          <Button
            color="warning"
            className={`px-1 me-n1 rounded-circle pdfViewer__btn ${
              !(pageNumber > 1) ? 'opacity-0 cursor-default' : 'opacity-100'
            }`}
            onClick={goToPrevPage}
            disabled={!(pageNumber > 1)}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </Button>

          <Card className={!numPages ? 'bg-transparent shadow-none' : ''}>
            {numPages && (
              <CardHeader
                className={`p-1 m-0 justify-content-center align-items-center ${headerColors[headerColor]} ${textColor[headerColor]}`}
                tag={Row}
              >
                <Col className="text-start p-0">
                  <CardText className="ms-75">{title}</CardText>
                </Col>
                <Col className="text-end p-0 order-md-3 d-flex justify-content-end">
                  {actions?.slice(0, 3)?.map((action, index) => (
                    <JpPDFAction
                      key={index}
                      color={action.color}
                      text={action.text}
                      icon={action.icon}
                      className={`${action?.className} d-none d-md-block`}
                      onClick={action?.onClick}
                      disabled={action?.disabled}
                    />
                  ))}
                  {actions?.length > 0 && (
                    <UncontrolledButtonDropdown>
                      <DropdownToggle color="link" className={`p-0 ${textColor[headerColor]} me-75`}>
                        <FontAwesomeIcon icon={faEllipsis} />
                      </DropdownToggle>
                      <DropdownMenu>
                        {actions?.map((action, index) => (
                          <DropdownItem
                            tag={Button}
                            block
                            key={index}
                            color="link"
                            onClick={action?.onClick}
                            className={`d-block d-md-none hover-${action?.color} ${action?.className}`}
                            disabled={action?.disabled}
                          >
                            <FontAwesomeIcon icon={action.icon} className="me-75" />
                            {action.text}
                          </DropdownItem>
                        ))}
                        {actions?.map((action, index) => (
                          <DropdownItem
                            tag={Button}
                            block
                            key={index}
                            color="link"
                            onClick={action?.onClick}
                            className={`hover-${action?.color} ${action?.className}`}
                            disabled={action?.disabled}
                          >
                            <FontAwesomeIcon icon={action.icon} className="me-75" />
                            {action.text}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  )}
                </Col>
                <Col md="4" className="text-center p-0 order-md-2">
                  {numPages && (
                    <CardText
                      tag={Form}
                      onSubmit={handleSubmit(onSubmit)}
                      className="d-flex align-items-center justify-content-center"
                    >
                      {/* {pageNumber} / {numPages} */}
                      <Controller
                        name="page"
                        control={control}
                        render={({ field }) => (
                          <Input {...field} type="number" min={1} max={numPages} className="w-auto me-75" />
                        )}
                      />
                      / {numPages}
                    </CardText>
                  )}
                </Col>
              </CardHeader>
            )}
            <CardBody className={`p-0 rounded-bottom pdfViewer ${zoom > 1 ? 'zoomed-in' : ''}`}>
              {numPages && (
                <ButtonToolbar className="pdfViewer__actions rounded shadow bg-white mt-2">
                  <JpButtonGroup className={`w-100 ${menuHoverNone && 'd-none'}`}>
                    <JpButton
                      type="action"
                      disabled={pageNumber === 1}
                      options={{
                        customIcon: <FontAwesomeIcon icon={faBackwardFast} />,
                        iconPosition: 'left',
                        onClick: () => changePageNumber(1),
                        textLess: true
                      }}
                    />
                    <JpButton
                      type="action"
                      disabled={numPages === pageNumber}
                      options={{
                        customIcon: <FontAwesomeIcon icon={faForwardFast} />,
                        iconPosition: 'left',
                        onClick: () => changePageNumber(numPages),
                        textLess: true
                      }}
                    />
                    <JpButton
                      type="action"
                      disabled={zoom === 3}
                      options={{
                        customIcon: <FontAwesomeIcon icon={faMagnifyingGlassPlus} />,
                        iconPosition: 'left',
                        onClick: zoomIn,
                        textLess: true
                      }}
                    />
                    <JpButton
                      type="action"
                      disabled={zoom === 2}
                      options={{
                        customIcon: <FontAwesomeIcon icon={faExpand} />,
                        iconPosition: 'left',
                        onClick: () => setZoom(2),
                        textLess: true
                      }}
                    />
                    <JpButton
                      type="action"
                      disabled={zoom === 0.5}
                      options={{
                        customIcon: <FontAwesomeIcon icon={faMagnifyingGlassMinus} />,
                        iconPosition: 'left',
                        onClick: zoomOut,
                        textLess: true
                      }}
                    />
                    {isFullScreen ? (
                      <JpButton
                        type="action"
                        options={{
                          customIcon: <FontAwesomeIcon icon={faCompressArrows} />,
                          iconPosition: 'left',
                          onClick: exitFullScreen,
                          textLess: true
                        }}
                      />
                    ) : (
                      <JpButton
                        type="action"
                        options={{
                          customIcon: <FontAwesomeIcon icon={faExpandArrows} />,
                          iconPosition: 'left',
                          onClick: goFullScreen,
                          textLess: true
                        }}
                      />
                    )}

                    {pdfName ? (
                      <JpButton
                        type="action"
                        options={{
                          customIcon: <FontAwesomeIcon icon={faFileArrowDown} />,
                          iconPosition: 'left',
                          onClick: () => downloadFile(pdf, pdfName),
                          textLess: true
                        }}
                      />
                    ) : (
                      <JpButton
                        type="action"
                        options={{
                          customIcon: <FontAwesomeIcon icon={faFileArrowDown} />,
                          iconPosition: 'left',
                          onClick: () => downloadFile(pdf, title),
                          textLess: true
                        }}
                      />
                    )}

                    <JpButton
                      type="action"
                      options={{
                        customIcon: <FontAwesomeIcon icon={faRotateRight} />,
                        iconPosition: 'left',
                        onClick: rotate,
                        textLess: true
                      }}
                    />
                  </JpButtonGroup>
                </ButtonToolbar>
              )}
              <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess} loading={<SpinnerLoader />}>
                <Page
                  id={`page_${pageNumber}`}
                  rotate={theta}
                  scale={zoom}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  pageNumber={pageNumber}
                />
              </Document>
            </CardBody>
          </Card>

          <Button
            className={`px-1 ms-n1 rounded-circle pdfViewer__btn ${
              !(numPages && pageNumber < numPages) ? 'opacity-0 cursor-default' : 'opacity-100'
            }`}
            disabled={!(numPages && pageNumber < numPages)}
            color="warning"
            onClick={goToNextPage}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </Button>
        </div>
      </>
    )
  } else {
    return (
      <>
        <Card>
          <CardBody className="">
            <CardText>{t('fileNotSupported')}</CardText>
            <JpButton
              type="download"
              options={{
                customIcon: <FontAwesomeIcon icon={faFileArrowDown} className="me-1" />,
                iconPosition: 'left',

                onClick: () => downloadFile(pdf, pdfName)
              }}
            />
          </CardBody>
        </Card>
      </>
    )
  }
}

JpPreviewFiles.propTypes = {
  pdf: PropTypes.string.isRequired,
  pdfName: PropTypes.string,
  title: PropTypes.string,
  headerColor: PropTypes.oneOf(['primary', 'success', 'danger', 'warning', 'info', 'dark', 'light', null, 'default']),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.oneOf(['primary', 'success', 'danger', 'warning', 'info', 'dark', 'light']),
      text: PropTypes.string,
      icon: PropTypes.any,
      className: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool
    })
  )
}

export default JpPreviewFiles
