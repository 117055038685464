// ** Icons Imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import JPButton from './JpButton'
import JpButtonGroup from './JpButtonGroup'
import PropTypes from 'prop-types'

const JpProfileTabs = ({ tabs, activeTab, toggleTab }) => {
  return (
    <JpButtonGroup>
      {tabs.map((tab, index) => {
        if (tab.noShow) return
          return (
            <JPButton
              key={index}
              type="group"
              text={tab?.text}
              options={{
                onClick: () => {
                  toggleTab(`${index + 1}`)
                  tab.customOnClick && tab.customOnClick()
                },
                customIcon: <FontAwesomeIcon icon={tab.icon} className="me-75" />,
                iconPosition: 'left'
              }}
              active={activeTab === `${index + 1}`}
              outline={activeTab !== `${index + 1}`}
            />
          )
      })}
    </JpButtonGroup>
  )
}

JpProfileTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.exact({
      customOnClick: PropTypes.func,
      text: PropTypes.string.isRequired,
      icon: PropTypes.any.isRequired,
      toggle: PropTypes.func,
      order: PropTypes.number,
      noShow: PropTypes.bool
    })
  ).isRequired,
  activeTab: PropTypes.string.isRequired,
  toggleTab: PropTypes.func.isRequired
}

export default JpProfileTabs
