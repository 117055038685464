import { JpLabel } from '@Intelli/utilities'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import AsyncSelect from 'react-select/async'

const JpAsyncSelect = ({
  name,
  title,
  control,
  loadOptions,
  placeHolder = '',
  invalid = false,
  options = {}
}) => {
  const { t } = useTranslation()

  return (
    <>
      {title && <JpLabel labelOptions={{ for: name }}>{title}</JpLabel>}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
            <AsyncSelect
                className={`${invalid && 'is-invalid'}`}
                classNames={{
                    control: state => `${invalid ? 'is-invalid' : ''}`
                }}
                hideSelectedOptions
                defaultOptions
                loadOptions={loadOptions}
                cacheOptions
                placeholder={placeHolder || t('input.select.placeHolder')}
                noOptionsMessage={() => t('input.select.noOptionsMessage')}
                loadingMessage={() => t('input.select.loadingMessage')}
                styles = {
                    {
                        control: styles => ({
                            ...styles,
                            borderColor: invalid ? '#EA5455' : styles.borderColor,
                            '&:hover': {
                                borderColor: invalid ? '#EA5455' : styles['&:hover'].borderColor
                            }
                        })
                    }
                }
                  
                {...field}
                {...options}
            />
        )}
      />
    </>
  )
}

export default JpAsyncSelect
