import moment from 'moment'

function parseMinutesToHours(minutes, withoutLabels = false) {
  const duration = moment.duration(minutes, 'minutes')
  const hours = Math.floor(duration.asHours())
  const seg = Math.floor(duration.seconds())
  const minutesTransform = duration.minutes()
  if (!(minutes > 0)) return '0 hrs'
  if (withoutLabels) {
    return `${hours > 0 ? `${hours < 10 ? '0' : ''}${hours}` : '00'}:${
      minutesTransform > 0 ? `${minutesTransform < 10 ? '0' : ''}${minutesTransform}` : '00'
    }`
  }
  return `${hours > 0 ? `${hours}h` : ''} ${minutesTransform > 0 ? `${minutesTransform}m` : ''} ${
    hours == 0 && minutesTransform === 0 ? `${seg}s` : ''
  }`
}

export default parseMinutesToHours
