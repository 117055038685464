// ** React Imports
import { forwardRef, useState } from 'react'

// ** Third Party Components
import Proptypes from 'prop-types'
import classnames from 'classnames'

// ** Reactstrap Imports
import { Badge, UncontrolledTooltip } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

const JpAvatar = forwardRef((props, ref) => {
  const { t } = useTranslation()
  // ** Props
  const {
    img,
    size,
    icon,
    errorIcon,
    color,
    status,
    badgeUp,
    badgeIconUp,
    content,
    tag: Tag,
    initials,
    imgWidth,
    className,
    badgeText,
    imgHeight,
    badgeColor,
    badgeClassName,
    imgClassName = 'cursor-default',
    contentStyles,
    statusIcon,
    uid,
    badgetDefault = false,
    cursorDefault = false,
    badgeUpTooltip,
    ...rest
  } = props

  // ** Function to extract initials from content
  const getInitials = str => {
    if (!!str) {
      const results = []
      const wordArray = str.split(' ')
      wordArray.forEach(e => {
        results.push(e[0])
      })
      return results.join('').toUpperCase()
    }
  }

  const [imgError, setImgError] = useState(false)

  return (
    <Tag
      className={classnames('avatar hover-div-button', {
        [className]: className,
        [`bg-${!imgError ? color : 'info'}`]: color,
        [`avatar-${size}`]: size
      })}
      ref={ref}
      {...rest}
    >
      {img === false || img === undefined || img === null || imgError ? (
        <span
          className={classnames(`avatar-content ${cursorDefault && 'cursor-default'}`, {
            'position-relative': badgeUp,
            [imgClassName]: imgClassName
          })}
          style={contentStyles}
        >
          {initials ? getInitials(content[0]) : content}
          {icon && !imgError && !!!content ? icon : null}
          {imgError ? <FontAwesomeIcon icon={errorIcon || 'fak fa-joobpay'} /> : null}
          {badgeUp ? (
            <Badge
              color={badgeColor ? badgeColor : 'primary'}
              className={`badge-sm badge-up ${badgeClassName ? badgeClassName : ''}`}
              pill
            >
              {badgeText ? badgeText : '0'}
            </Badge>
          ) : null}
          {badgeIconUp ? (
            <>
              <div
                className={`avatar-distintion ${badgeClassName ? badgeClassName : ''}`}
              >
                {badgeText ? badgeText : '0'}
              </div>
            </>
          ) : null}
        </span>
      ) : (
        <>
          <img
            className={classnames({
              [imgClassName]: imgClassName
            })}
            src={img}
            alt="A"
            height={imgHeight && !size ? imgHeight : 32}
            width={imgWidth && !size ? imgWidth : 32}
            onError={e => {
              setImgError(true)
            }}
          />
          {badgeIconUp ? (
            <>
              <div
                // color={badgeColor ? badgeColor : 'primary'}
                className={`avatar-distintion ${badgeClassName ? badgeClassName : ''}`}
                // pill
              >
                {badgeText ? badgeText : '0'}
              </div>
            </>
          ) : null}
        </>
      )}
      {status ? (
        <span
          className={classnames({
            [`avatar-status-${status}`]: status,
            [`avatar-status-${size}`]: size
          })}
        ></span>
      ) : null}
      {statusIcon ? (
        <span
          className={classnames('bg-info hover-button d-flex align-items-center justify-content-center', {
            [`avatar-status-${size}`]: size
          })}
          style={{
            width: '2.5rem',
            height: '2.5rem',
            right: '-0.5rem',
            fontSize: '0.625em'
          }}
        >
          <FontAwesomeIcon icon={statusIcon} size="2xs" className={`text-white`} />
        </span>
      ) : null}
      {badgetDefault && (
        <>
          <div
            id={`added${uid}`}
            style={{
              borderRadius: '50px',
              height: '20px',
              width: '20px'
            }}
            className="ms-2 mb-2 position-absolute text-center"
          >
            <FontAwesomeIcon icon={'fa-solid fa-star'}></FontAwesomeIcon>
          </div>
          <UncontrolledTooltip target={`added${uid}`}>{t('default')}</UncontrolledTooltip>
        </>
      )}
    </Tag>
  )
})

export default JpAvatar

// ** PropTypes
JpAvatar.propTypes = {
  icon: Proptypes.node,
  src: Proptypes.string,
  badgeUp: Proptypes.bool,
  content: Proptypes.string,
  badgeText: Proptypes.oneOfType([Proptypes.string, Proptypes.element]),
  className: Proptypes.string,
  imgClassName: Proptypes.string,
  contentStyles: Proptypes.object,
  size: Proptypes.oneOf(['sm', 'lg', 'xl', 'xxl', 'xxxl']),
  tag: Proptypes.oneOfType([Proptypes.func, Proptypes.string]),
  status: Proptypes.oneOf(['online', 'offline', 'away', 'busy']),
  imgHeight: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
  imgWidth: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
  badgeColor: Proptypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'info',
    'warning',
    'dark',
    'light-primary',
    'light-secondary',
    'light-success',
    'light-danger',
    'light-info',
    'light-warning',
    'light-dark',
    'light-cyan',
    'yellow',
    'purple'
  ]),
  badgeClassName: Proptypes.string,
  color: Proptypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'info',
    'warning',
    'dark',
    'cyan',
    'green',
    'light-primary',
    'light-secondary',
    'light-success',
    'light-danger',
    'light-info',
    'light-warning',
    'light-dark',
    'light-cyan',
    'light-green',
    'light-teal',
    'light-purple',
    'light-yellow',
    'white',
    'yellow',
    'purple',
    'light',
    'light-blue'
  ]),
  initials(props) {
    if (props['initials'] && props['content'] === undefined) {
      return new Error('content prop is required with initials prop.')
    }
    if (props['initials'] && typeof props['content'] !== 'string') {
      return new Error('content prop must be a string.')
    }
    if (typeof props['initials'] !== 'boolean' && props['initials'] !== undefined) {
      return new Error('initials must be a boolean!')
    }
  }
}

// ** Default Props
JpAvatar.defaultProps = {
  tag: 'div'
}
