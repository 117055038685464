import PropTypes from 'prop-types'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const defaultProps = {
  isOpen: false,
  options: {
    modalOptions: {
      centered: true
    },
    footerOptions: {
      className: 'justify-content-center'
    }
  }
}

/**
 *
 * @param {Object} props
 * @param {Element} props.headerContent
 * @param {Element} props.bodyContent
 * @param {Element} props.footerContent
 * @param {Boolean} props.isOpen
 * @param {Function} props.toggle
 * @param {Object} props.options
 * @param {Object} props.options.modalOptions
 * @param {Object} props.options.headerOptions
 * @param {Object} props.options.bodyOptions
 * @param {Object} props.options.footerOptions
 * @param {String} props.options.modalOptions.className
 * @param {Object} props.options.modalOptions.cssModule
 * @param {Function} props.options.modalOptions.tag
 * @param {Boolean} props.options.modalOptions.backdrop
 * @param {Boolean} props.options.modalOptions.centered
 * @param {Boolean} props.options.modalOptions.fade
 * @param {Boolean} props.options.modalOptions.fullscreen
 * @param {String} props.options.modalOptions.size
 * @param {Boolean} props.options.modalOptions.scrollable
 * @param {Number} props.options.modalOptions.zIndex
 * @param {String} props.options.headerOptions.className
 * @param {Object} props.options.headerOptions.cssModule
 * @param {Function} props.options.headerOptions.tag
 * @param {Element} props.options.headerOptions.closeButton
 * @param {String} props.options.bodyOptions.className
 * @param {Object} props.options.bodyOptions.cssModule
 * @param {Function} props.options.bodyOptions.tag
 * @param {String} props.options.footerOptions.className
 * @param {Object} props.options.footerOptions.cssModule
 * @param {Function} props.options.footerOptions.tag
 * @returns {JSX.Element} A standard Joobpay modal
 */

const JpModal = ({
  headerContent,
  bodyContent,
  footerContent,
  isOpen = defaultProps.isOpen,
  toggle,
  bodyClassName='',
  options = defaultProps.options
}) => {
  const JpModalOptions = { ...defaultProps.options, ...options }

  return (
    <Modal
      toggle={e => {
        e.type !== 'click' && toggle()
      }}
      isOpen={isOpen}
      {...JpModalOptions.modalOptions}
    >
      {headerContent && (
        <ModalHeader toggle={toggle} {...JpModalOptions.headerOptions}>
          {headerContent}
        </ModalHeader>
      )}
      <ModalBody className={bodyClassName} {...JpModalOptions.bodyOptions}>{bodyContent}</ModalBody>
      {footerContent && <ModalFooter {...JpModalOptions.footerOptions}>{footerContent}</ModalFooter>}
    </Modal>
  )
}

JpModal.propTypes = {
  headerContent: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]).isRequired,
  bodyContent: PropTypes.element.isRequired,
  footerContent: PropTypes.element,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  options: PropTypes.shape({
    modalOptions: PropTypes.exact({
      className: PropTypes.string,
      cssModule: PropTypes.object,
      tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
      backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['static'])]),
      centered: PropTypes.bool,
      fade: PropTypes.bool,
      fullscreen: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['sm', 'md', 'lg', 'xl'])]),
      size: PropTypes.oneOf(['sm', 'lg']),
      scrollable: PropTypes.bool,
      zIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }),
    headerOptions: PropTypes.exact({
      className: PropTypes.string,
      cssModule: PropTypes.object,
      tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
      closeButton: PropTypes.element
    }),
    bodyOptions: PropTypes.exact({
      className: PropTypes.string,
      cssModule: PropTypes.object,
      tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
    }),
    footerOptions: PropTypes.exact({
      className: PropTypes.string,
      cssModule: PropTypes.object,
      tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
    })
  })
}

export default JpModal
