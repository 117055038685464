import JPButton from "../buttons/JpButton"
import JpModal from "./JpModal"
import PropTypes from "prop-types"
import JpFileInput from "../inputs/JpFileInput"

const JpFileModal = ({
  isOpen,
  headerContent,
  bodyContent,
  onSubmit,
  toggle,
  bodyPosition = 'top',
  fileInputOptions,
  files,
  setFiles,
  fileInvalid,
  setFileInvalid,
  acceptedFormats = ['.pdf'],
  options = {
    footerOptions: {
      className: 'justify-content-center'
    }
  }
}) => {
  return (
    <JpModal
      isOpen={isOpen}
      headerContent={headerContent}
      toggle={() => toggle()}
      bodyContent={
        <>
          {bodyPosition === 'top' && bodyContent}
          <JpFileInput
            files={files}
            setFiles={setFiles}
            fileInvalid={fileInvalid}
            setFileInvalid={setFileInvalid}
            acceptedFormats={acceptedFormats}
            {...fileInputOptions}
          />
          {bodyPosition === 'bottom' && bodyContent}
        </>
      }
      options={options}
      footerContent={
        <>
          <JPButton type="cancel" options={{ onClick: () => toggle() }} />
          <JPButton
            type="save"
            color="green"
            options={{
              onClick: () => {
                onSubmit()
                // toggle()
              },
              iconless: false
            }}
          />
        </>
      }
    />
  )
}

JpFileModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    headerContent: PropTypes.element.isRequired,
    bodyContent: PropTypes.element.isRequired,
    bodyPosition: PropTypes.oneOf(["top", "bottom"]),
    onSubmit: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    fileInputOptions: PropTypes.exact({
        name: PropTypes.string.isRequired,
        maxFileSize: PropTypes.number,
        acceptedFormats: PropTypes.arrayOf(PropTypes.string),
        multiple: PropTypes.bool
    }),
    files: PropTypes.array.isRequired,
    setFiles: PropTypes.func.isRequired,
    fileInvalid: PropTypes.bool.isRequired,
    setFileInvalid: PropTypes.func.isRequired
}

export default JpFileModal