import { Card, CardTitle, CardBody, CardSubtitle, Row, CardHeader } from 'reactstrap'

const JpCard = ({ title, subtitle, cardFooter, img, alt }) => {
  return (
 
      <Card className="mb-0 pt-0 h-100">
        <CardHeader className="">
          {title && <CardTitle tag="h4">{title}</CardTitle>}
          {subtitle && <CardSubtitle className="text-muted">{subtitle}</CardSubtitle>}
        </CardHeader>
        <CardBody className="d-flex flex-column justify-content-around pb-0">
          <img className="img-fluid" src={img} alt={alt}/>
          {cardFooter && <div className="p-0">{cardFooter}</div>}
        </CardBody>
      </Card>

  )
}

export default JpCard
