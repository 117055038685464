import { faBell } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, CardBody, Progress } from 'reactstrap'
import { JpAvatar, JpText, JpButton } from '@Intelli/utilities'
import { useTranslation } from 'react-i18next'

const ProfileProgress = ({
  icon,
  title,
  subtitle,
  btnText,
  navigate,
  percent,
  color,
  closeButton = false,
  closeButtonFunction = () => {}
}) => {
  const { t } = useTranslation()

  const setColor = percent => {
    if (percent <= 20) return 'danger'
    if (percent <= 50) return 'warning'
    if (percent <= 75) return 'yellow'
    if (percent === 100) return 'success'
  }

  return (
    <Card className="mb-2">
      <CardBody>
      {closeButton && <Button size='md' className='p-0 pb-1' color='white' onClick={closeButtonFunction}><i class="fa-regular fa-xmark"></i></Button>}
        <div className="d-flex align-items-center ">
          <JpAvatar className="me-75" icon={<FontAwesomeIcon icon={icon} />} color={'info'} />
          <JpText type="p-text" children={`${title} ${percent}% ${t('complete')}`} />
          <div className="ms-auto">
            <JpButton
              type="next"
              text={btnText}
              color="info"
              options={{
                iconless: true,
                onClick: () => (window.location.href = navigate)
              }}
            />
          </div>
        </div>
        <div className="my-1">
          <JpText type="p-text" children={`${subtitle}`} />
        </div>
        <Progress value={percent} className="mb-1" color={setColor(percent)}>{`${percent}%`}</Progress>
      </CardBody>
    </Card>
  )
}

export default ProfileProgress
