// ** Third Party Components
import { useTranslation } from 'react-i18next'
import ReactCountryFlag from 'react-country-flag'

import { language$ } from '@Intelli/utilities'

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from 'reactstrap'

const Languages = [
  {
    countryCode: 'us',
    i18n: 'en',
    text: 'English'
  },
  {
    countryCode: 'es',
    i18n: 'es',
    text: 'Español'
  }
]

const IntlDropdown = ({color = "secondary"}) => {
  const { i18n } = useTranslation()

  const handleLangUpdate = (e, lang) => {
    e.preventDefault()
    i18n.changeLanguage(lang)
    language$.next(lang)
  }

  const lng = Languages.find(e => e.i18n === i18n.language)
  if (!lng) return null
  return (
    <UncontrolledDropdown
      href="/"
      tag="li"
      className="dropdown-language nav-item"
    >
      <DropdownToggle
        href="/"
        tag="a"
        className={`nav-link text-${color}`}
        onClick={e => e.preventDefault()}
      >
        <ReactCountryFlag
          svg
          className="country-flag flag-icon"
          countryCode={lng.countryCode}
        />
        <span className="selected-language text-capitalize ms-75">{lng.i18n}</span>
      </DropdownToggle>
      <DropdownMenu className="mt-0" end>
        {Languages.map((lng, index) => (
          <DropdownItem
            key={index}
            href="/"
            tag="a"
            onClick={e => handleLangUpdate(e, lng.i18n)}
          >
            <ReactCountryFlag
              className="country-flag"
              countryCode={lng.countryCode}
              svg
            />
            <span className="ms-1">{lng.text}</span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default IntlDropdown
