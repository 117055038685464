import React, { useEffect, useState } from 'react'

const CountDownTimer = ({ hoursMinSecs }) => {
  const { hours = 0, minutes = 0, seconds = 60 } = hoursMinSecs
  const [[hrs, mins, secs], setTime] = useState([hours, minutes, seconds])

  const reset = () => setTime([parseInt(hours), parseInt(minutes), parseInt(seconds)])

  const tick = () => {
    if (hrs === 0 && mins === 0 && secs === 0) reset()
    else if (mins === 0 && secs === 0) {
      setTime([hrs - 1, 59, 59])
    } else if (secs === 0) {
      setTime([hrs, mins - 1, 59])
    } else {
      setTime([hrs, mins, secs - 1])
    }
  }

  useEffect(() => {
    const timerId = setInterval(() => tick(), 1000)
    return () => clearInterval(timerId)
  })

  return (
    <p style={{ margin: 0, display: 'inline' }}>
      {mins.toString().padStart(2, '0')}:{secs.toString().padStart(2, '0')}
    </p>
  )
}

export default CountDownTimer