import { Card, CardBody, CardHeader, CardTitle, Col, Progress, Row, UncontrolledTooltip } from 'reactstrap'
import JpCardSkeleton from '../skeletons/JpCardSkeleton'
import JpText from '../general/JpText'
import JpAvatar from '../general/JpAvatar'
import JpPercentageText from '../general/JpPercentageText'

const ActivityBarCard = ({
  title,
  subtitle,
  percentage,
  lastPeriodText,
  progressData,
  tableData,
  loading = false,
  cardClassName = '',
  cardHeaderClassname=''
}) => {
  if (loading) return <JpCardSkeleton loading={loading} graph />

  const setRounded = index => {
    if (index === 0) return 'rounded-start'
    if (index === progressData.length - 1) return 'rounded-end'
  }

  return (
    <>
      <Card className={cardClassName}>
        <CardHeader className={`pb-0 ${cardHeaderClassname}`}>
          <div className="d-flex justify-content-between align-items-center w-100">
            <CardTitle>{title}</CardTitle>
            <div className="d-flex">
              {!!subtitle && <span className="me-75">{subtitle}</span>}
              {percentage && <JpPercentageText percentage={parseFloat(percentage)} periodText={lastPeriodText} />}
            </div>
          </div>
        </CardHeader>
        <CardBody className="py-0 d-flex flex-column justify-content-between justify-content-md-center justify-content-xl-beetween">
          <div className="mb-1">
            <Progress
              multi
              className="rounded"
              style={{
                height: '25px'
              }}
            >
              {progressData?.map((item, i) => {
                return (
                  <Progress
                    key={i}
                    id={`progress-${i}`}
                    className={setRounded(i)}
                    bar
                    color={item?.color}
                    value={`${item?.percent}`}
                  >
                    {item?.percent > 20 && `${item?.percent}%`}
                    <UncontrolledTooltip target={`progress-${i}`}>{`${item.name}`}</UncontrolledTooltip>
                  </Progress>
                )
              })}
            </Progress>
          </div>
          <div className='mb-75'>
            {progressData?.map((item, index) => {
              return (
                <Row key={`row-${index}`} className="align-items-center py-50">
                  <Col md="4" className="d-flex align-items-center">
                    <JpAvatar
                      id={`${item?.name}-${index}`}
                      className={'me-1'}
                      color={item?.color}
                      icon={<i className={item?.icon} />}
                    />
                    <JpText className="text-center" type="span-table-text" children={`${item?.name}`} />
                    <UncontrolledTooltip target={`${item?.name}-${index}`}>{`${item?.name}`}</UncontrolledTooltip>
                  </Col>
                  <Col>
                    <JpText className="text-center" type="span-table-text" children={`${item?.count}`} />
                  </Col>
                  <Col>
                    <JpText className="text-center" type="span-table-text" children={`${item?.time}`} />
                  </Col>
                  <Col>
                    <JpText className="text-center" type="span-table-text" children={`${item?.percent}%`} />
                  </Col>
                </Row>
              )
            })}
            {/* <Row className="border-top align-items-center py-75">
              <Col className="d-flex align-items-center">
                <JpAvatar className={'me-1'} color={'green'} icon={<i className={'fa-solid fa-arrow-trend-up'} />} />
                <JpText type="span-table-text">{'Activos'}</JpText>
              </Col>
              <Col>
                <JpText className="text-center" type="span-table-text">
                  {'17'}
                </JpText>
              </Col>
              <Col>
                <JpText className="text-center" type="span-table-text">
                  {'63h 15m'}
                </JpText>
              </Col>
              <Col>
                <JpText className="text-center" type="span-table-text">
                  {'83.16%'}
                </JpText>
              </Col>
            </Row>
             <Row className="border-top align-items-center py-75">
              <Col className="d-flex align-items-center">
                <JpAvatar className={'me-1'} color={'green'} icon={<i className={'fa-solid fa-arrow-trend-up'} />} />
                <JpText type="span-table-text">{'Activos'}</JpText>
              </Col>
              <Col>
                <JpText className="text-center" type="span-table-text">
                  {'17'}
                </JpText>
              </Col>
              <Col>
                <JpText className="text-center" type="span-table-text">
                  {'63h 15m'}
                </JpText>
              </Col>
              <Col>
                <JpText className="text-center" type="span-table-text">
                  {'83.16%'}
                </JpText>
              </Col>
            </Row>
            <Row className="border-top align-items-center py-75">
              <Col className="d-flex align-items-center">
                <JpAvatar className={'me-1'} color={'green'} icon={<i className={'fa-solid fa-arrow-trend-up'} />} />
                <JpText type="span-table-text">{'Activos'}</JpText>
              </Col>
              <Col>
                <JpText className="text-center" type="span-table-text">
                  {'17'}
                </JpText>
              </Col>
              <Col>
                <JpText className="text-center" type="span-table-text">
                  {'63h 15m'}
                </JpText>
              </Col>
              <Col>
                <JpText className="text-center" type="span-table-text">
                  {'83.16%'}
                </JpText>
              </Col>
            </Row> */}
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default ActivityBarCard
