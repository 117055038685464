import { Label } from 'reactstrap'
import PropTypes from 'prop-types'

/**
 *
 * @param {element} children
 * @param {object} labelOptions
 * @returns A Joobpay styled label
 * @example import { JpLabel } from "@Intelli/utilities"
 * @example <JpLabel for="example">Example</JpLabel>
 */

const JpLabel = ({ children, fontSize, labelOptions, className }) => {
  return (
    <Label {...labelOptions} className={`fw-bold fs-${fontSize || '6'} ${className || ' '}`}>
      {children}
    </Label>
  )
}

JpLabel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  fontSize: PropTypes.string,
  labelOptions: PropTypes.shape({
    className: PropTypes.string,
    for: PropTypes.string,
    hidden: PropTypes.bool,
    check: PropTypes.bool,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
    id: PropTypes.string
  })
}

export default JpLabel
