import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import JPButton from "../buttons/JpButton"
import JpAvatar from "./JpAvatar"
import PropTypes from 'prop-types'

const JpPDFAction = ({text, color, icon, className, onClick, disabled}) => {
    return (
        <JPButton
            text={text}
            type="action" 
            className={`py-50 d-flex align-items-center justify-content-center text-white me-1 p-0 ${className ? className : ''}`}
            disabled={disabled}
            options={ 
                {
                    customIcon: <FontAwesomeIcon icon={icon} className="me-75" />,
                    iconPosition: 'left',
                    onClick
                }
            } 
        />
    )
}

// JpPDFAction.propTypes = {
//     text: PropTypes.string,
//     color: PropTypes.oneOf(['primary', 'success', 'danger', 'warning', 'info', 'dark']),
//     icon: PropTypes.any,
//     className: PropTypes.string,
//     onClick: PropTypes.func,
//     disabled: PropTypes.bool
// }

export default JpPDFAction