export const HOURS_STATES = {
  ABSENT: {
    name: 'ABSENT',
    color: 'light-yellow',
    iconAvatar: 'fa-solid fa-calendar-xmark',
    showApp: false
  },
  WORKING: {
    name: 'WORKING',
    color: 'success',
    iconAvatar: 'fa-solid fa-arrow-right-to-bracket',
    showApp: false
  },
  CLOCK_OUT: {
    name: 'CLOCKED_OUT',
    color: 'secondary',
    iconAvatar: 'fa-solid fa-arrow-right-from-bracket',
    showApp: false
  },
  CLOCKED_OUT: {
    name: 'CLOCKED_OUT',
    color: 'secondary',
    iconAvatar: 'fa-solid fa-arrow-right-from-bracket',
    showApp: false
  },
  HOLIDAY: {
    name: 'HOLIDAY',
    color: 'purple',
    iconAvatar: 'fa-solid fa-calendar-heart',
    showApp: false
  },
  PERMISSION: {
    name: 'PERMISSION',
    color: 'cyan',
    iconAvatar: 'fa-solid fa-calendar-pen',
    showApp: false
  },
  TIME_OFF: {
    name: 'TIME_OFF',
    color: 'light-blue',
    iconAvatar: 'fa-solid fa-calendar-circle-user',
    showApp: false
  }
}
