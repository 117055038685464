import { BarElement, CategoryScale, Legend, LinearScale, Title, Tooltip, Chart } from 'chart.js'
import { Bar } from 'react-chartjs-2'

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const colors = {
  success: '#81C995',
  danger: '#EA5455',
  warning: '#FF9F43',
  info: '#0198FE',
  primary: '#0364B6',
  blue: '#00cfe8',
  cyan: '#0dcaf0',
  green: '#28c76f',
  teal: '#20c997'
}

const JpBars = ({ data, color, labels, height, seriesLabel, tooltipFormatter, noData = false, isToday = true }) => {
  const barBackTrack = {
    id: 'barBackTrack',
    beforeDatasetsDraw: (chart, args, plugins) => {
      const {
        ctx,
        data,
        chartArea,
        scales: { x, y }
      } = chart

      const { width, height, bottom } = chartArea

      const firstBarData = chart.getDatasetMeta(0).data

      const barThickness = firstBarData[0]?.width

      ctx.save()

      ctx.fillStyle = '#E9E9E9'

      /* if (data.length === 0 || noData) { */
      const xPositions = firstBarData?.map(item => item.x)

      xPositions.forEach((xPos, index) => {
        ctx.beginPath()
        ctx.roundRect(xPos - barThickness * 0.5, bottom, barThickness, -height, [0, 0, 20, 20])
        ctx.fill()
      })
      /* } */
    }
  }

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          display: true
        },
        border: {
          display: false
        }
      },
      y: {
        display: false,
        grid: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        display: false,
        labels: { usePointStyle: true }
      },
      tooltip: {
        callbacks: {
          ...(tooltipFormatter
            ? {
                label(context) {
                  if (context.dataIndex === data.length - 1) {
                    return `${context.dataset.label}: ${tooltipFormatter(context.parsed.y)}`
                  }
                  return `${tooltipFormatter(context.parsed.y)}`
                },
                title() {
                  return null
                }
              }
            : {})
        }
      }
    }
  }

  const barData = {
    labels,
    datasets: [
      {
        label: seriesLabel,
        // backgroundColor: colors[color],
        backgroundColor: [...Array(data.length - 1).fill(colors[color]), isToday ? colors['green'] : colors[color]],
        borderColor: 'transparent',
        maxBarThickness: 10,
        borderRadius: 10,
        data
      }
    ]
  }

  return (
    <Bar
      data={barData}
      options={options}
      height={height}
      plugins={[
        {
          beforeDraw(c) {
            const legends = c.legend.legendItems
            legends[0].fillStyle = colors['warning']
          }
        },
        barBackTrack
      ]}
    />
  )
}

export default JpBars
