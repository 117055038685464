import { BehaviorSubject } from 'rxjs'
import TwoFactorModal from './components/TwoFactorModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons'
import './configs/i18n'
import i18next from 'i18next'
import moment from 'moment'
import { ErrorBoundary } from 'react-error-boundary'

export const SpinnerLoader = () => {
  return (
    <div className="row jusify-content-center align-items-center text-center text-primary">
      <div className="col-12">
        <FontAwesomeIcon icon="fak fa-joobpay" flip size="4x" />
      </div>
    </div>
  )
}

const getUserLanguage = () => {
  const lng = window.navigator.userLanguage || window.navigator.language
  try {
    return lng.split('-')[0]
  } catch (error) {
    return 'en'
  }
}

export const CountrySelectorInput = require('./components/CountrySelector').default

export const language$ = new BehaviorSubject(localStorage.getItem('lng') || getUserLanguage())

language$.subscribe(lang => {
  localStorage.setItem('lng', lang)
  i18next.changeLanguage(lang)
  // moment.locale(lang)
})

export const user$ = new BehaviorSubject(JSON.parse(localStorage.getItem('user')) || {})

user$.subscribe(user => {
  localStorage.setItem('user', JSON.stringify(user))
})

export const colors$ = new BehaviorSubject({})

export const parseName = require('./functions/parseName').default
export const TwoFactor = TwoFactorModal
export const parseMinutesToHours = require('./functions/parseMinutesToHours').default

export const TimerButton = require('./components/TimerButton').default

export const globalHandler = require('./components/handler').default

export const Captcha = require('./components/Captcha').default

export const ProfileHeader = require('./components/ProfileHeader').default

export const IntlDropdown = require('./components/IntlDropdown').default

export const NoData = require('./components/NoData').default

// ** Fucntions **
export const dateLocalizer = require('./functions/localizeDate')
export const parseCurrency = require('./functions/parseCurrency').default
export const hideEmail = require('./functions/hideEmail').default
export const capitalizeLabels = require('./functions/capitalizeLabels').default
export const firstCapitalLetter = require('./functions/firstCapitalLetter').default
export const GeoLocation = require('./functions/geoLocation').default
export const percentage = require('./functions/percentage').default
export const formatDatesUtc = require('./functions/formatDate')
export const findMinMaxDates = require('./functions/findMinMaxDatesTimeline').default
export const getUserOperatingSystem = require('./functions/getUserOperatingSystem').default
export const removeSpecialChars = require('./functions/removeSpecialChars').default
export const parseHoursForMinutes = require('./functions/parseHoursForMinutes').default
export const isBase64 = require('./functions/isBase64').default
export const base64ToFile = require('./functions/base64ToFile').default
export const hasTextInsideTags = require('./functions/hasTextInsideTags').default
export const parseHTMLAndExtractImages = require('./functions/parseHTMLAndExtractImages').default
export const iconStarFn = require('./functions/iconStars').default
export const productivityColor = require('./functions/productivityColor').default


// ** Buttons **
export const JpButton = require('./components/buttons/JpButton').default
export const JpButtonGroup = require('./components/buttons/JpButtonGroup').default
export const JpStepperButtons = require('./components/buttons/JpStepperButtons').default
export const JpProfileTabs = require('./components/buttons/JpProfileTabs').default

// ** Modals **
// *** SweetAlert ***
export const SweetModal = require('./components/SweetModal').default
// *** Reactstrap ***
export const JpModal = require('./components/modals/JpModal').default
export const JpFileModal = require('./components/modals/JpFileModal').default

// ** Inputs **
export const JpFileInput = require('./components/inputs/JpFileInput').default
export const JpLabel = require('./components/inputs/JpLabel').default
export const JpCountryInput = require('./components/inputs/JpCountryInput').default
export const JpCountryCodesInput = require('./components/inputs/JpCountryCodesInput').default
export const JpDatePicker = require('./components/inputs/JpDatePicker').default
export const JpLanguageSelect = require('./components/inputs/JpLanguageSelect').default
export const JpAsyncSelect = require('./components/inputs/JpAsyncSelect').default
export const JpPhoneInput = require('./components/inputs/JpPhoneInput').default
export const JpStateInput = require('./components/inputs/JpStateInput').default
export const JpAsyncCreatableSelect = require('./components/inputs/JpAsyncCreatableSelect').default
export const JpInputHoursCustom = require('./components/inputs/JpInputHoursCustom').default

// ** Cards **
export const BlueHeaderCard = require('./components/cards/BlueHeaderCard').default
export const CardCompany = require('./components/cards/CardCompany').default
export const ProgressCard = require('./components/cards/ProgressCard').default
export const JpBarsCard = require('./components/graphs/JpBarsCard').default
export const JpStackedBarsCard = require('./components/cards/JpStackedBarsCard').default
export const JpPieCardTime = require('./components/cards/JpPieCardTime').default
export const JpVerticalBarsCard = require('./components/cards/JpVerticalBarsCard').default
export const JpRadialBarCard = require('./components/cards/JpRadialBarCard').default
export const JpHorizontalAvatarCard = require('./components/cards/JpHorizontalAvatarCard').default
export const JpBarsCardV1 = require('./components/cards/JpBarsCardV1').default
export const JpVerticalAvatarCard = require('./components/cards/JpVerticalAvatarCard').default
export const JpProgressRadial = require('./components/graphs/JpProgressRadial').default
export const JpDonutWithTextGraph = require('./components/graphs/JpDonutWithTextGraph').default
export const JpActivityBarCard = require('./components/cards/JpActivityBarCard').default
export const JpListCardGraph = require('./components/graphs/JpListCardGraph').default
// ** Tables **
export const JpTable = require('./components/tables/JpTable').default
export const JpTableV1 = require('./components/tables/JpTableV1').default
export const JpTableAction = require('./components/tables/JpTableAction').default
export const JpTableActionsCol = require('./components/tables/JpTableActionsCol').default
export const JpTableTitleName = require('./components/tables/JpTableTitleName').default

// ** General **
export const JpModuleCard = require('./components/general/JpModuleCard').default
export const JpText = require('./components/general/JpText').default
export const JpPreviewFiles = require('./components/general/JpPreviewFiles').default
export const JpPDFAction = require('./components/general/JpPDFAction').default
export const JpPDFVertical = require('./components/general/JpPDFVertical').default
export const JpAvatar = require('./components/general/JpAvatar').default
export const JpAvatarGroup = require('./components/general/JpAvatarGroup').default
export const JpPieGraph = require('./components/general/JpPieGraph').default
export const JpOrdersBarChart = require('./components/general/JpOrdersBarChart').default
export const JpRadiarChart = require('./components/general/JpRadiarChart').default
export const JpGraphCard = require('./components/general/JpGraphCard').default
export const JpStatsIconCard = require('./components/general/JpStatsIconCard').default
export const JpCountryFlag = require('./components/general/JpCountryFlag').default
export const JpCurrencyInput = require('./components/inputs/JpCurrencyInput').default
export const JpTextAreaInput = require('./components/inputs/JpTextAreaInput').default
export const JpCurrencyNumber = require('./components/general/JpCurrencyNumber').default
export const JpGauge = require('./components/general/JpGauge').default
export const JpTabActions = require('./components/general/JpTabActions').default
export const JpFilterButtons = require('./components/general/JpFilterButtons').default
export const JpCard = require('./components/general/JpCard').default
export const JpAdvanceCard = require('./components/general/JpAdvanceCard').default
export const JpAdvanceCardModal = require('./components/general/JpAdvanceCardModal').default
export const JpPercentageText = require('./components/general/JpPercentageText').default
export const JpProgressBar = require('./components/cards/JpProgressBar').default
export const JpBombLight = require('./components/general/JpBombLight').default
export const ContractorProfilePhoto = require('./components/general/ContractorProfilePhoto').default
export const ContractorNameInfo = require('./components/general/ContractorNameInfo').default
export const HasPermissionModules = require('./functions/permission').default
export const JpErrorBoundary = require('./components/general/JpErrorBoundary').default
export const JpPopover = require('./components/general/JpPopover').default
export const JpCustomTooltip = require('./components/general/JpCustomTooltip').default
export const OptionComponentSelectAvatar = require('./components/general/OptionComponentSelectAvatar').default
export const OptionComponentPrioritySelect = require('./components/general/OptionComponentPrioritySelect').default
export const OptionComponentPriorityValue = require('./components/general/OptionComponentPriorityValue').default


// ** Skeletons **
export const JpCardSkeleton = require('./components/skeletons/JpCardSkeleton').default
export const JpSmallGraphSkeleton = require('./components/skeletons/JpSmallGraphSkeleton').default

// ** Ratings **
export const RatingStars = require('./components/ratings/RatingStars').default

// ** Constants **
export const CONTRACT_TYPE_VARIABLES = require('./constants/contracts').CONTRACT_TYPE_VARIABLES
export const PRODUCTUVITY_STATUS_VALUES = require('./constants/productivity').PRODUCTUVITY_STATUS_VALUES
export const productivityStatesIcons = require('./constants/productivityStatesIcons').productivityStatesIcons
export const productivityStates = require('./constants/productivityStates').productivityStates
export const CONTRACT_MODALITY_VARIABLES = require('./constants/contracts').CONTRACT_MODALITY_VARIABLES
export const TIME_PERMISSIONS_STATUS = require('./constants/time').TIME_PERMISSIONS_STATUS
export const TIME_PERMISSIONS_STATUS_ARRAY = require('./constants/time').TIME_PERMISSIONS_STATUS_ARRAY
export const PRODUCTIVITY_DASHBOARD = require('./constants/productivityDahsboard').PRODUCTIVITY_DASHBOARD
export const HOURS_STATES = require('./constants/timeStates').HOURS_STATES
export const PROJECTS_STATE_COLORS = require('./constants/projects/projectStateColors').projectStateColors
export const PROJECTS_PRIORITY_COLORS = require('./constants/projects/projectStateColors').priorityColors
export const TOOLBAR_OPTIONS = require('./constants/projects/textEditor').TOOLBAR_OPTIONS
