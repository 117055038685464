import JpHorizontalBars from './JpHorizontalBars'
import { JpAvatar } from '@Intelli/utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card, CardBody, CardHeader } from 'reactstrap'
import JpCardSkeleton from '../skeletons/JpCardSkeleton'

const JpBarsCard = ({
  title,
  icon = false,
  data,
  labels,
  color,
  barLabelFormatter,
  tickFormatter,
  className = '',
  bodyClassName = '',
  customBarsColors=[],
  loading=false
  
}) => {
  if (loading) return <JpCardSkeleton loading={loading} graph />
  return (
    <Card className={`${className}`}>
      <CardHeader className="justify-content-start pb-0">
        {icon && <JpAvatar icon={<FontAwesomeIcon icon={icon} />} color={color} />}
        <h4 className="card-title">{title}</h4>
      </CardHeader>
      <CardBody className={bodyClassName}>
        <JpHorizontalBars
          data={data}
          labels={labels}
          color={color}
          barLabelFormatter={barLabelFormatter}
          tickFormatter={tickFormatter}
          customBarsColors={customBarsColors}
        />
      </CardBody>
    </Card>
  )
}

export default JpBarsCard
