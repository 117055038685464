const parseCurrency = (value, currency = 'US$') => {
  let returnValue = 0
  if (typeof value === 'number') {
    returnValue = value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
  }
  if (typeof value === 'string') {
    returnValue = parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
  }
  return `${currency} ${returnValue}`
}

export default parseCurrency
