// ** React Imports
import moment from 'moment'

// ** Third Party Components
import Flatpickr from 'react-flatpickr'

import 'flatpickr/dist/flatpickr.css'
import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import i18next from 'i18next'
import { Spanish } from 'flatpickr/dist/l10n/es'
import { faXmark } from '@fortawesome/pro-solid-svg-icons'
import { useRef } from 'react'
import { UncontrolledTooltip } from 'reactstrap'

const JpDatePicker = ({
  disabled = false,
  value,
  setPicker,
  className = '',
  classNames,
  id = 'picker-1',
  minDate,
  maxDate,
  disabledDates = [],
  clearable = false,
  customOnClear,
  tooltipText
}) => {
  moment.locale(navigator.language)
  const localFormat = moment('', 'L').localeData()._longDateFormat.L

  const picker = useRef(null)

  return (
    <Flatpickr
      className={`form-control ${className}`}
      value={value instanceof moment ? value?.format() : value}
      onChange={([date]) => {
        setPicker(moment(date))
      }}
      id={id}
      options={{
        locale: i18next.language === 'es' ? Spanish : null,
        clickOpens: !disabled,
        minDate,
        maxDate,
        disable: disabledDates,
        disableMobile: true
      }}
      disabled={disabled}
      readOnly={disabled}
      ref={picker}
      render={({ value, className }, ref) => {
        return (
          <span ref={ref} id={id} className={`${className} d-flex justify-content-between align-items-center`}>
            {!moment(value).isValid() ? localFormat : moment(value).format('L')}
            <div className="d-flex">
              {clearable && (
                <FontAwesomeIcon
                  icon={faXmark}
                  onClick={() => {
                    picker.current.flatpickr.clear()
                    setPicker('')
                    {
                      customOnClear && customOnClear()
                    }
                  }}
                  className="ms-1 text-primary"
                />
              )}

              <FontAwesomeIcon icon={faCalendar} className={`${clearable ? 'ms-1' : 'ms-75'} text-primary`} />
            </div>
            {tooltipText && <UncontrolledTooltip target={id}>{tooltipText}</UncontrolledTooltip>}
          </span>
        )
      }}
    />
  )
}

export default JpDatePicker
