import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { UncontrolledTooltip } from 'reactstrap'

const ProfileHeader = ({ Avatar, Tabs, Name, Details, verify = '', detailsDiv=<></> }) => {
  const { t } = useTranslation()

  return (
    <div className="d-flex mb-2 align-items-center justify-content-between">
      <div className="d-flex me-2 align-items-center">
        {Avatar}
        <div className="ms-1">
          <div className="d-flex align-items-center text-center gap-1">
            <h3 className="mb-0 text-start">{Name}</h3>
            {verify === 'APPROVED' && (
              <>
                <FontAwesomeIcon icon={faBadgeCheck} size="xl" className="text-green" id="verify" />
                <UncontrolledTooltip target="verify">{t('verify')}</UncontrolledTooltip>
              </>
            )}
          </div>
          <span>{Details}</span>
          {detailsDiv && detailsDiv}
        </div>
      </div>
      {Tabs && Tabs}
    </div>
  )
}

export default ProfileHeader
