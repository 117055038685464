import React from 'react'
import { Fragment } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import JpAvatar from '../general/JpAvatar'

const JpSmallGraphSkeleton = () => {
  return (
    <Card className='h-100 p-0 m-0'>
      <CardBody>
        <Fragment>
          <Col lg="1" className="ps-1">
            <div className="placeholder-glow">
              <div className="placeholder rounded-circle">
                <JpAvatar size="lg" color="light-secondary" content="" />
              </div>
            </div>
          </Col>
          <Col>
            <div className="placeholder-glow">
              <div className="d-flex flex-column">
                <Row>
                  <small className="placeholder rounded col-6 mb-75">...</small>
                </Row>
                <Row>
                  <span className="fw-bold placeholder rounded col-12">...</span>
                </Row>
              </div>
            </div>
          </Col>
        </Fragment>
      </CardBody>
    </Card>
  )
}

export default JpSmallGraphSkeleton
